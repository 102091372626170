import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDV5RfHidLP4Q-nSVPHAsFLzeSgw69wHL0",
    authDomain: "crm-landers-download.firebaseapp.com",
    projectId: "crm-landers-download",
    storageBucket: "crm-landers-download.appspot.com",
    messagingSenderId: "897497945188",
    appId: "1:897497945188:web:d4e3fd8d214d52a65ffb71"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export { storage };
