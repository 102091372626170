export const flagColors = flg => {
    switch (flg) {
        case "isAdmin": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Management": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Sales advisor": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Underwriter": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Customer care advisor": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Marketing": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Insurer": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "isPartner": return {
            backgroundColor: "rgb(233 183 116)",
            color: "rgb(175 100 0)",
            border: "1px solid rgb(175 100 0)",
            borderRadius: "5px"
        };
        default: return {
            backgroundColor: "rgb(251 157 248)",
            color: "rgb(203 30 196)",
            border: "1px solid rgb(203 30 196)",
            borderRadius: "5px"
        };
    };
};

export const flagNameColors = flg => {
    switch (flg) {
        case "Admin": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Management": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Sales advisor": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Underwriter": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Customer care advisor": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Marketing": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Insurer": return {
            backgroundColor: "rgba(142, 134, 252, 0.904)",
            color: "rgb(53, 43, 201)",
            border: "1px solid rgb(72, 63, 215)",
            borderRadius: "5px"
        };
        case "Partner": return {
            backgroundColor: "rgb(233 183 116)",
            color: "rgb(175 100 0)",
            border: "1px solid rgb(175 100 0)",
            borderRadius: "5px"
        };
        default: return {
            backgroundColor: "rgb(251 157 248)",
            color: "rgb(203 30 196)",
            border: "1px solid rgb(203 30 196)",
            borderRadius: "5px"
        };
    };
};