import React from 'react';
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";

export default function StatusesModal(props) {
    const [spinner, setSpinner] = React.useState(true);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [edit, setEdit] = React.useState(false);
    const [editData, setEditData] = React.useState([]);

    const nameRef = React.useRef(null);
    const numericRef = React.useRef(null);
    const colorRef = React.useRef(null);
    const isApprovingRef = React.useRef(null);
    const isSellingRef = React.useRef(null);

    const getAllStatuses = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statuses/getAllStatuses`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setMessage(null);
                setData(res.data);
            }
            else {
                return setMessage('Došlo je do problema...');
            }
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const addStatus = () => {
        if (!nameRef.current.value || !numericRef.current.value || !colorRef.current.value) {
            return setMessage('Molimo ispunite polja');
        }
        else {
            setBtnSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statuses/addStatus`,
            data: {
                Name: nameRef.current.value,
                Numeric: numericRef.current.value,
                Color: colorRef.current.value,
                IsApproving: isApprovingRef.current.checked ? 1 : 0,
                IsSelling: isSellingRef.current.checked ? 1 : 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setMessage(null);
                getAllStatuses();
            }
            else {
                return setMessage('Došlo je do problema...');
            }
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    const deleteStatus = (ID) => {
        if (ID) setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statuses/removeStatus`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setMessage(null);
                getAllStatuses();
            }
            else {
                return setMessage('Došlo je do problema...');
            }
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const editStatus = (ID, Name, Numeric, Color, IsApproving, IsSelling) => {
        setEdit(true);
        setEditData([{ Name: Name, Numeric: Numeric, Color: Color, ID: ID, IsApproving: IsApproving, IsSelling: IsSelling }]);
    }

    const editFinish = () => {
        if (edit) {
            setBtnSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/statuses/updateStatus`,
                data: {
                    ID: editData[0].ID,
                    Name: nameRef.current.value,
                    Numeric: numericRef.current.value,
                    Color: colorRef.current.value,
                    IsApproving: isApprovingRef.current.checked ? 1 : 0,
                    IsSelling: isSellingRef.current.checked ? 1 : 0
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    setMessage(null);
                    setEdit(false);
                    getAllStatuses();
                }
                else {
                    return setMessage('Došlo je do problema...');
                }
            }).catch(() => {
                return setMessage('Server ne reagira...');
            }).finally(() => {
                setBtnSpinner(false);
            });
        }
    }

    React.useEffect(() => {
        getAllStatuses();
    }, []);

    return (
        <div className='component__statusesModal'>
            <div className='component__statusesModal__wrapper'>
                <div className='component__statusesModal__wrapper__header'>
                    <h1>Upravljanje statusima</h1>
                    <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
                </div>
                <div className='component__statusesModal__wrapper__body'>
                    {!edit && <div className='component__statusesModal__wrapper__body__newStatus'>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Naziv</p>
                            <input ref={nameRef} type='text' />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Numerička oznaka (ID)</p>
                            <input ref={numericRef} type='number' />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Da li znači prodano?</p>
                            <input ref={isSellingRef} type='checkbox' />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Da li je approving?</p>
                            <input ref={isApprovingRef} type='checkbox' />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Boja (HEX)</p>
                            <input ref={colorRef} type='text' />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__action'>
                            <p>Kreiraj</p>
                            <div onClick={() => addStatus()} className='component__statusesModal__wrapper__body__newStatus__action__button'>
                                {!btnSpinner && <p>Kreiraj</p>}
                                {btnSpinner && <Spinner style={{ width: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' align='center' />}
                            </div>
                        </div>
                    </div>}
                    {edit && <div className='component__statusesModal__wrapper__body__newStatus'>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Naziv</p>
                            <input ref={nameRef} type='text' defaultValue={editData[0].Name} />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Numerička oznaka (ID)</p>
                            <input ref={numericRef} type='number' defaultValue={editData[0].Numeric} />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Da li znači prodano?</p>
                            <input ref={isSellingRef} type='checkbox' defaultChecked={editData[0].IsSelling} />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Da li je approving?</p>
                            <input ref={isApprovingRef} type='checkbox' defaultChecked={editData[0].IsApproving} />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__field'>
                            <p>Boja (HEX)</p>
                            <input ref={colorRef} type='text' defaultValue={editData[0].Color} />
                        </div>
                        <div className='component__statusesModal__wrapper__body__newStatus__action'>
                            <p>Uredi</p>
                            <div onClick={() => editFinish()} className='component__statusesModal__wrapper__body__newStatus__action__button'>
                                {!btnSpinner && <p>Uredi</p>}
                                {btnSpinner && <Spinner style={{ width: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' align='center' />}
                            </div>
                        </div>
                    </div>}
                    {message && <p style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', fontFamily: 'Figtree', marginTop: '5px' }}>{message}</p>}
                    <div className='component__statusesModal__wrapper__body__list'>
                        <h1>Postojeći statusi</h1>
                        {spinner && <Spinner style={{ width: '28px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' align='center' />}

                        {!spinner ? data?.data?.map((item) => {
                            return <div className='component__statusesModal__wrapper__body__list__status'>
                                <p>{item.Numeric}</p>
                                <p>{item.Name}</p>
                                <p>{item.IsApproving ? 'A' : 'NA'}</p>
                                <p>{item.IsSelling ? 'P' : 'NP'}</p>
                                <img onClick={() => editStatus(item.ID, item.Name, item.Numeric, item.Color, item.IsApproving, item.IsSelling)} src='images/edit.svg' alt='' />
                                <img onClick={() => deleteStatus(item.ID)} src='images/trash.svg' alt='' />
                            </div>
                        }) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
