
import React from 'react';
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import moment from 'moment/moment';

export default function LeadInfo(props) {
    const [comments, setComments] = React.useState([]);
    const [message, setMessage] = React.useState(null);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [secBtnSpinner, setSecBtnSpinner] = React.useState(false);
    const [thirdBtnSpinner, setThirdBtnSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [sellError, setSellError] = React.useState(null);
    const [selectedExDate, setSelectedExDate] = React.useState('');
    const [selectedCaDate, setSelectedCaDate] = React.useState('');
    const [scheduleSpinner, setScheduleSpinner] = React.useState(false);
    const [scheduleError, setScheduleError] = React.useState(null);
    const [scheduleSuccessMsg, setScheduleSuccessMsg] = React.useState(null);
    const [responsiblePerson, setResponsiblePerson] = React.useState('');

    const [lead, setLead] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [activitySpinner, setActivitySpinner] = React.useState(true);
    const [currentStatus, setCurrentStatus] = React.useState([]);
    const [approvingStatus, setApprovingStatus] = React.useState([]);
    const [statuses, setStatuses] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = React.useState(null);
    const [statusSpinner, setStatusSpinner] = React.useState(true);
    const [scriptSpinner, setScriptSpinner] = React.useState(true);
    const [scriptPath, setScriptPath] = React.useState(null);

    const commentRef = React.useRef(null);
    const nameRef = React.useRef(null);
    const phoneRef = React.useRef(null);
    const cityRef = React.useRef(null);
    const zipRef = React.useRef(null);
    const streetRef = React.useRef(null);
    const quantityOrderRef = React.useRef(null);
    const priceOrderRef = React.useRef(null);
    const noteRef = React.useRef(null);

    const getComments = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leadActivity/getAllActivities`,
            data: {
                filters: [{ name: 'LeadID', op: 'eq', value: props?.leadID }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setComments(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setActivitySpinner(false);
        });
    }

    const addActivity = () => {
        if (!commentRef.current.value) return setError('Sva polja moraju biti popunjena!');

        setBtnSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leadActivity/addActivity`,
            data: {
                User: props.user?.FirstName,
                LeadID: props?.leadID,
                Comment: commentRef.current.value,
                Quantity: 1,
                Price: 1
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getLeadInfo();
                getComments();
                commentRef.current.value = '';
                setError(null);
            } else {
                return setError('Došlo je do greške prilikom slanja podataka...');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    const deleteLead = (ID) => {
        setSecBtnSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/deleteLead`,
            data: {
                ID: ID,
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha"
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.getAll();
                setError(null);
                props.onClose();
            } else {
                return setError('Došlo je do greške prilikom brisanja upita...');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setSecBtnSpinner(false);
        });
    }

    const handleDateChangeEX = (event) => {
        setSelectedExDate(event.target.value);
    };

    const handleDateChangeCA = (event) => {
        setSelectedCaDate(event.target.value);
    };

    const scheduleCall = (type) => {
        if (!selectedExDate && !selectedCaDate) return;

        setScheduleSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/extensions/addExtension`,
            data: {
                Type: type,
                LeadName: lead?.data?.Name,
                LeadProduct: lead?.data?.Product,
                LeadPhone: lead?.data?.Phone,
                LeadCountry: lead?.data?.Country,
                LeadID: lead?.data?.ID,
                ScheduledDate: type === 'extension' ? selectedExDate : selectedCaDate,
                OfferID: lead?.data?.OfferID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.getAll();
                getComments();
                setError(null);
                if(type === 'extension'){
                    setScheduleSuccessMsg({ type: type, message: 'Uspješno ste zakazali produzenje'});
                }
                else{
                    setScheduleSuccessMsg({ type: type, message: 'Uspješno ste zakazali poziv'});
                }
            } else {
                return setScheduleError('Došlo je do greške prilikom slanja podataka...');
            };
        }).catch(() => {
            return setScheduleError('Server ne reagira...');
        }).finally(() => {
            setScheduleSpinner(false);
        });
    }

    const returnAgent = (ID) => {
        props.agents?.data?.map(agent => {
            if (agent.ID == ID) {
                return setResponsiblePerson(agent.FirstName + ' ' + agent.LastName);
            }
        })
    }

    const getLeadInfo = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadByID`,
            data: {
                ID: props.leadID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setLead(res.data);
            }
            else {
                setMessage('Došlo je do problema sa dohvatanjem podataka, 265');
            }
        }).catch(() => {
            setMessage('Server ne reagira, 268');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const getStatuses = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statuses/getAllStatuses`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setCurrentStatus(res.data?.data.find((status) => status.Numeric === lead?.data?.Status));
                setApprovingStatus(res.data?.data.find((status) => status.IsApproving === true));
                setStatuses(res.data);
            }
            else {
                setMessage('Došlo je do problema sa dohvatanjem podataka, 265');
            }
        }).catch(() => {
            setMessage('Server ne reagira, 268');
        }).finally(() => {
            setStatusSpinner(false);
        });
    }

    const makeSell = () => {
        if (
            !nameRef.current.value ||
            !phoneRef.current.value ||
            !cityRef.current.value ||
            !streetRef.current.value ||
            !quantityOrderRef.current.value ||
            !priceOrderRef.current.value
        ) {
            return setSellError('Popunite obavezna polja!');
        }

        setThirdBtnSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/addOrder`,
            data: {
                Token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                Name: nameRef.current.value,
                Phone: phoneRef.current.value,
                City: cityRef.current.value,
                ZIP: zipRef.current.value,
                Street: streetRef.current.value,
                Quantity: quantityOrderRef.current.value,
                Price: priceOrderRef.current.value,
                Product: lead?.data?.Product,
                LeadID: lead?.data?.ID,
                OfferID: lead?.data?.OfferID,
                PartnerID: lead?.data?.PartnerID,
                Country: lead?.data?.Country,
                Note: noteRef.current.value,
                Type: lead?.data?.Type,
                NewStatus: approvingStatus.Numeric,
                UserID: props.user?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.getAll();
            }
        }).catch(() => {
            return setSellError('Popunite obavezna polja!');
        }).finally(() => {
            setThirdBtnSpinner(false);
            getLeadInfo();
        });
    }

    const changeStatus = (ID, status) => {
        if (selectedStatus === null) {
            return;
        }
        else {
            setStatusSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/changeStatus`,
            data: {
                ID: ID,
                Status: status
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.getAll();
                getLeadInfo();
            }
            else {
                setMessage('Došlo je do problema sa dohvatanjem podataka, 283');
            }
        }).catch(() => {
            setMessage('Server ne reagira, 286');
        }).finally(() => {
            setStatusSpinner(false);
        });
    }

    const getScript = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getOfferScriptPath`,
            data: {
                OfferID: props.offerID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setScriptPath(res.data.data);
            }
            else {
                setMessage('Došlo je do problema sa dohvatanjem podataka, 313');
            }
        }).catch(() => {
            setMessage('Server ne reagira, 286');
        }).finally(() => {
            setScriptSpinner(false);
        });
    }

    React.useEffect(() => {
        returnAgent(lead?.data?.ResponsiblePerson);
    }, [lead]);

    React.useEffect(() => {
        getComments();
        getLeadInfo();
    }, []);

    React.useEffect(() => {
        if (lead?.data?.ID) {
            getStatuses();
        }
    }, [lead]);

    React.useEffect(() => {
        if (props.offerID) {
            getScript();
        }
    }, [props.offerID]);

    return <div className='component__leadInfoModal'>
        <div className='component__leadInfoModal__wrapper'>
            <div className='component__leadInfoModal__wrapper__header'>
                <h1>Detalji o klijentu sa lead-a: #{props.leadID}</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>

            {!spinner && !activitySpinner && !error && !message ? <div className='component__leadInfoModal__wrapper__body'>
                <div className='component__leadInfoModal__wrapper__body__left'>
                    <div className='component__leadInfoModal__wrapper__body__left__top'>
                        <div className='component__leadInfoModal__wrapper__body__left__top__info'>
                            <p>Ime i prezime: {lead?.data?.Name}</p>
                            <p>Broj telefona: {lead?.data?.Phone}</p>
                            <p>Proizvod: {lead?.data?.Product}</p>
                            <p>Tip: {lead?.data?.Type}</p>
                            <p>Cijena: {lead?.data?.Price}</p>
                            <p>Država: {lead?.data?.Country}</p>
                            <p>Oznaka: {lead?.data?.Tag}</p>
                            <p>LeadHash: {lead?.data?.LeadHash}</p>
                            {props.user.Flags.isCCmanager || props.user.Flags.isAdmin ? <p>Odgovorni agent: {responsiblePerson}</p> : null}
                            <p>Zadnja obrada: {lead?.data?.Processed ? moment(lead?.data?.Processed).format('DD.MM.YYYY.') : 'Nije obrađen'}</p>
                            <p>Publisher ID: {lead?.data?.PublisherID}</p>
                            <p>Webmaster ID: {lead?.data?.WebmasterID}</p>
                            <p>Partner ID: {lead?.data?.PartnerID}</p>
                            <p>Offer ID: {lead?.data?.OfferID}</p>
                            <p>Vrijeme: {moment(lead?.data?.createdAt).format('DD.MM.YYYY.')}</p>
                            <div className='component__leadInfoModal__wrapper__body__left__top__info__buttons'>
                                {!props.user.Flags.isCCagent && <div onClick={() => deleteLead(props.leadID)} className='component__leadInfoModal__wrapper__body__left__top__info__buttons__deleteLead'>
                                    {!secBtnSpinner && <p>Obriši lead</p>}
                                    {secBtnSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '14px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                                </div>}
                                {props.user.Flags.isCCmanager || props.user.Flags.isAdmin ?
                                    <div className='component__leadInfoModal__wrapper__body__left__top__info__buttons__responsible'
                                        onClick={(e) => animateBox(e, <AgentListModal curStatus={lead?.data?.Status} curAgentID={lead?.data?.ResponsiblePerson} refresh={getLeadInfo} leadID={props.leadID} agents={props.agents} />)} >
                                        <p>Promijeni odg. agenta</p>
                                    </div> : null}
                            </div>
                        </div>
                        <div className='component__leadInfoModal__wrapper__body__left__top__comments'>
                            <div className='component__leadInfoModal__wrapper__body__left__top__comments__header'>
                                <textarea ref={commentRef} placeholder='Upiši komentar' />
                                <div onClick={() => addActivity()} className='component__leadInfoModal__wrapper__body__left__top__comments__header__button'>
                                    {!btnSpinner && <p>Komentariši</p>}
                                    {btnSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '14px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                                </div>
                            </div>
                            <div className='component__leadInfoModal__wrapper__body__left__top__comments__list'>
                                {
                                    !message && !spinner && !error && !activitySpinner ? comments?.data?.map(item => {
                                        return <div className='component__leadInfoModal__wrapper__body__left__top__comments__list__comment'>
                                            <strong>{item.User}</strong>
                                            <p>{item.Comment}</p>
                                        </div>
                                    }) : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className='component__leadInfoModal__wrapper__body__left__bottom'>
                        <div className='component__leadInfoModal__wrapper__body__left__bottom__upper'>
                            {!currentStatus.IsApproving && <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell'>
                                <p>Popuniti samo ako je ostvarena prodaja! Popuniti pažljivo!</p>
                                <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__container'>
                                    <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__container__field'>
                                        <label>Ime kupca</label>
                                        <input ref={nameRef} type='text' />
                                    </div>
                                    <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__container__field'>
                                        <label>Broj telefona</label>
                                        <input ref={phoneRef} type='text' />
                                    </div>
                                    <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__container__field'>
                                        <label>Grad</label>
                                        <input ref={cityRef} type='text' />
                                    </div>
                                    <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__container__field'>
                                        <label>Poštanski broj (opcionalno)</label>
                                        <input ref={zipRef} type='text' />
                                    </div>
                                    <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__container__field'>
                                        <label>Ulica</label>
                                        <input ref={streetRef} type='text' />
                                    </div>
                                    <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__container__field'>
                                        <label>Količina</label>
                                        <input ref={quantityOrderRef} type='text' />
                                    </div>
                                    <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__container__field'>
                                        <label>Cijena</label>
                                        <input ref={priceOrderRef} type='number' />
                                    </div>
                                    <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__container__field'>
                                        <label>Napomena(opcionalno)</label>
                                        <input ref={noteRef} type='text' />
                                    </div>
                                </div>
                                <div onClick={() => makeSell()} className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__button'>
                                    {!thirdBtnSpinner && <p>Zaključi prodaju</p>}
                                    {thirdBtnSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '14px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                                </div>
                            </div>}
                            {currentStatus.IsApproving && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell'>
                                <p style={{ fontSize: '16px', fontWeight: '600' }}>Ovaj lead je uspješno prodan</p>
                                <div onClick={(e) => animateBox(e, <EditPriceModal refresh={getLeadInfo} leadID={lead?.data?.ID} />)} className='component__leadInfoModal__wrapper__body__left__bottom__upper__sell__editPriceBtn'><p>Koriguj cijenu</p></div>
                            </div>}
                            <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__statuses'>
                                {!statusSpinner && <div style={{ background: currentStatus.Color, boxShadow: '0 0 4px' + currentStatus.Color }} className='component__leadInfoModal__wrapper__body__left__bottom__upper__statuses__current'>
                                    <p>Trenutni status: {currentStatus.Name}</p>
                                </div>}
                                {!statusSpinner && <div className='component__leadInfoModal__wrapper__body__left__bottom__upper__statuses__list'>
                                    {statuses?.data?.map((status) => {
                                        if (!status.IsApproving) {
                                            return <div
                                                onClick={() => setSelectedStatus(status.Numeric)}
                                                className='component__leadInfoModal__wrapper__body__left__bottom__upper__statuses__list__item'
                                                style={{ border: selectedStatus !== null ? selectedStatus == status.Numeric ? '2px solid green' : null : null }}
                                            >
                                                <p>{status.Name}</p>
                                            </div>
                                        }
                                    })}
                                </div>}
                                {!statusSpinner && <div onClick={() => changeStatus(lead?.data?.ID, selectedStatus)} className='component__leadInfoModal__wrapper__body__left__bottom__upper__statuses__list__button'>
                                    <p>Promijeni</p>
                                </div>}
                                {statusSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                            </div>
                        </div>
                        <div className='component__leadInfoModal__wrapper__body__left__bottom__buttons'>
                            <div className='component__leadInfoModal__wrapper__body__left__bottom__buttons__field'>
                                <p>Produženje:</p>
                                <input type='date'
                                    value={selectedExDate}
                                    onChange={handleDateChangeEX}
                                />
                                <div onClick={() => scheduleCall('extension')} className='component__leadInfoModal__wrapper__body__left__bottom__buttons__field__btn'>
                                    {
                                        scheduleSpinner && !scheduleError ? <Spinner align='center' style={{ marginTop: '0px', width: '12px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' /> : ''
                                    }
                                    {
                                        !scheduleSpinner && !scheduleError ? <p>Zakaži</p> : ''
                                    }
                                </div>
                            </div>
                            <div className='component__leadInfoModal__wrapper__body__left__bottom__buttons__field'>
                                <p>Pozvati:</p>
                                <input type='date'
                                    value={selectedCaDate}
                                    onChange={handleDateChangeCA}
                                />
                                <div onClick={() => scheduleCall('callAgain')} className='component__leadInfoModal__wrapper__body__left__bottom__buttons__field__btn'>
                                    {
                                        scheduleSpinner && !scheduleError ? <Spinner align='center' style={{ marginTop: '0px', width: '12px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' /> : ''
                                    }
                                    {
                                        !scheduleSpinner && !scheduleError ? <p>Zakaži</p> : ''
                                    }
                                </div>
                            </div>
                            {scheduleSuccessMsg && scheduleSuccessMsg.type === 'extension' ?
                                <p style={{ color: '#ffffff', fontFamily: 'Figtree', fontSize: '15px' }}>{scheduleSuccessMsg.message}</p> : null}
                            {scheduleSuccessMsg && scheduleSuccessMsg.type === 'callAgain' ?
                                <p style={{ color: '#ffffff', fontFamily: 'Figtree', fontSize: '15px' }}>{scheduleSuccessMsg.message}</p> : null}
                            {!scheduleSpinner && scheduleError ? <p style={{ color: 'red' }}>{scheduleError}</p> : null}
                        </div>
                    </div>
                </div>
                <div className='component__leadInfoModal__wrapper__body__right'>
                    {scriptSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '32px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                    {scriptPath &&
                        <iframe
                            src={scriptPath}
                            width="100%"
                            height="100%"
                            title="PDF Viewer"
                        >
                        </iframe>}
                </div>
            </div> : <Spinner align='center' style={{ marginTop: '20px', width: '40px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}

            {message && <p style={{ color: 'white' }}>{message}</p>}
            {error && <p style={{ color: 'white' }}>{error}</p>}
        </div>
    </div>
}

const EditPriceModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const priceRef = React.useRef(null);

    const editPrice = () => {
        if (!priceRef.current.value) return setMessage('Popunite polje');
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/editPrice`,
            data: {
                ID: props.leadID,
                NewPrice: priceRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.refresh();
                props.onClose();
            }
            else {
                setMessage('Došlo je do greške pri slanju podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='component__editPrice'>
        <div className='component__editPrice__wrapper'>
            <div className='component__editPrice__wrapper__header'>
                <h1 className='component__editPrice__wrapper__header__h1'>Koriguj cijenu</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            <div className='component__editPrice__wrapper__body'>
                <p>Unesi novu cijenu (samo broj)</p>
                <input ref={priceRef} type='number' />
                <div onClick={() => editPrice()} className='component__editPrice__wrapper__body__button'>
                    {!spinner && <p>Sačuvaj</p>}
                    {spinner && <Spinner style={{ width: '14px' }} color='#ffffff' />}
                </div>
                {message && <p style={{ color: 'white', fontSize: '15px' }}>{message}</p>}
            </div>
        </div>
    </div>
}

const AgentListModal = (props) => {
    const changeResponsiblePerson = (agentID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/changeResponsiblePerson`,
            data: {
                ID: props.leadID,
                AgentID: agentID,
                OldAgentID: props.curAgentID,
                Status: props.curStatus
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.onClose();
                props.refresh();
            }
        }).catch(() => {

        });
    }

    return <div className='component__leadInfoModal__agentList'>
        <div className='component__leadInfoModal__agentList__wrapper'>
            <div className='component__leadInfoModal__agentList__wrapper__header'>
                <p>Promjena odg. agenta</p>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            <div className='component__leadInfoModal__agentList__wrapper__list'>
                {props?.agents?.data?.map(agent => {
                    return <p onClick={() => changeResponsiblePerson(agent.ID)}>{agent.FirstName} {agent.LastName}</p>
                })}
            </div>
        </div>
    </div>
}