import React from 'react';
import "./index.scss";
import Spinner from '../../components/Spinner';
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment';

export default function Finances(props) {
    const [selectedCountry, setSelectedCountry] = React.useState({ name: 'Bosna', value: 'BA' });
    const [countryDropdown, setCountryDropdown] = React.useState(false);
    const [period, setPeriod] = React.useState('today');
    const [error, setError] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/getStats`,
            data: {
                period: period,
                country: selectedCountry.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setError('Došlo je do greške 32');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const returnCurrency = (country) => {
        switch (country) {
            case 'BA':
                return 'KM';
                break;
            case 'RS':
                return 'RSD';
                break;
            case 'ME':
                return 'EUR';
                break;
            case 'HR':
                return 'EUR';
                break;
            case 'MK':
                return 'MKD';
                break;
            case 'XK':
                return 'EUR';
                break;
            case 'AL':
                return 'ALL';
                break;
            case 'HU':
                return 'HUF';
                break;
            case 'BG':
                return 'BGN';
                break;
        }
    }

    React.useEffect(() => {
        getData();
    }, [selectedCountry, period]);

    return <div className='route__finances'>
        <div className='route__finances__header'>
            <div className='route__finances__header__dropdown'>
                <div onClick={() => setCountryDropdown(d => !d)} className={`route__finances__header__dropdown__head ${countryDropdown ? 'route__finances__header__dropdown__head--active' : ''}`}>
                    <p>{selectedCountry.name}</p>
                </div>
                <div className={`route__finances__header__dropdown__body ${countryDropdown ? 'route__finances__header__dropdown__body--active' : ''}`}>
                    <p onClick={() => { setSelectedCountry({ name: 'Bosna', value: 'BA' }); setCountryDropdown(false) }}>Bosna</p>
                    <p onClick={() => { setSelectedCountry({ name: 'Srbija', value: 'RS' }); setCountryDropdown(false) }}>Srbija</p>
                    <p onClick={() => { setSelectedCountry({ name: 'Crna Gora', value: 'ME' }); setCountryDropdown(false) }}>Crna Gora</p>
                    <p onClick={() => { setSelectedCountry({ name: 'Hrvatska', value: 'HR' }); setCountryDropdown(false) }}>Hrvatska</p>
                    <p onClick={() => { setSelectedCountry({ name: 'Makedonija', value: 'MK' }); setCountryDropdown(false) }}>Makedonija</p>
                    <p onClick={() => { setSelectedCountry({ name: 'Kosovo', value: 'XK' }); setCountryDropdown(false) }}>Kosovo</p>
                    <p onClick={() => { setSelectedCountry({ name: 'Albanija', value: 'AL' }); setCountryDropdown(false) }}>Albanija</p>
                    <p onClick={() => { setSelectedCountry({ name: 'Mađarska', value: 'HU' }); setCountryDropdown(false) }}>Mađarska</p>
                    <p onClick={() => { setSelectedCountry({ name: 'Bugarska', value: 'BG' }); setCountryDropdown(false) }}>Bugarska</p>
                </div>
            </div>
            <div onClick={(e) => animateBox(e, <ExpensesModal />)} className='route__finances__header__button'>
                <p>Upravljanje troškovima</p>
            </div>
        </div>
        {!spinner && <div className='route__finances__body'>
            <div className='route__finances__body__filters'>
                <div className='route__finances__body__filters__container'>
                    <span onClick={() => setPeriod('today')} style={{ background: period == 'today' ? '#232d4e' : 'transparent' }}><p>24h</p></span>
                    <span onClick={() => setPeriod('yesterday')} style={{ background: period == 'yesterday' ? '#232d4e' : 'transparent' }}><p>Jučer</p></span>
                    <span onClick={() => setPeriod('last7Days')} style={{ background: period == 'last7Days' ? '#232d4e' : 'transparent' }}><p>7D</p></span>
                    <span onClick={() => setPeriod('currentMonth')} style={{ background: period == 'currentMonth' ? '#232d4e' : 'transparent' }}><p>30D</p></span>
                    <span onClick={() => setPeriod('currentYear')} style={{ background: period == 'currentYear' ? '#232d4e' : 'transparent' }}><p>Godina</p></span>
                </div>
            </div>
            <div className='route__finances__body__kpis'>
                <div className='route__finances__body__kpis__kpi'>
                    <span>
                        <p>Promet</p>
                        <p>{(data?.data?.totalRevenue).toFixed(2)} {returnCurrency(data?.data?.country)}</p>
                    </span>
                    <img src='images/promet.png' alt='' />
                </div>
                <div className='route__finances__body__kpis__kpi'>
                    <span>
                        <p>Troškovi</p>
                        <p>{(data?.data?.totalCosts).toFixed(2)} {returnCurrency(data?.data?.country)}</p>
                    </span>
                    <img src='images/troskovi.png' alt='' />
                </div>
                <div className='route__finances__body__kpis__kpi'>
                    <span>
                        <p>Povrat</p>
                        <p>{(data?.data?.totalReturns).toFixed(2)} {returnCurrency(data?.data?.country)}</p>
                    </span>
                    <img src='images/vracenih.png' alt='' />
                </div>
                <div className='route__finances__body__kpis__kpi'>
                    <span>
                        <p>Dobit</p>
                        <p>{(data?.data?.totalProfit).toFixed(2)} {returnCurrency(data?.data?.country)}</p>
                    </span>
                    <img src='images/dobit.png' alt='' />
                </div>
            </div>
            {window.innerWidth > 920 ? <div className='route__finances__body__tableNames'>
                <h1>Tabela troškova</h1>
                <h1>Tabela vraćenih paketa</h1>
            </div> : <div className='route__finances__body__tableNames'>
                <h1>Tabela troškova</h1>
            </div>}
            <div className='route__finances__body__tables'>
                <div className='route__finances__body__tables__expenses'>
                    <div className='route__finances__body__tables__expenses__header'>
                        <p>Naziv</p>
                        <p>Količina</p>
                        <p>Vrijednost</p>
                    </div>
                    <div className='route__finances__body__tables__expenses__content'>
                        {
                            data?.data?.expenses?.map(exp => {
                                return <div className='route__finances__body__tables__expenses__content__item'>
                                    <p>{exp.name}</p>
                                    <p>{exp.totalQuantity}</p>
                                    <p>{Number(exp.totalValue).toFixed(2)} {returnCurrency(data?.data?.country)}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
                {window.innerWidth < 921 && <div style={{display: 'block', height: 'fit-content'}} className='route__finances__body__tableNames'>
                    <h1>Tabela vraćenih paketa</h1>
                </div>}
                <div className='route__finances__body__tables__returnedOrders'>
                    <div className='route__finances__body__tables__returnedOrders__header'>
                        <p>ID</p>
                        <p>Ime</p>
                        <p>Telefon</p>
                        <p>Vrijednost</p>
                    </div>
                    <div className='route__finances__body__tables__returnedOrders__content'>
                        {
                            data?.data?.returnedOrders?.map(rorr => {
                                return <div className='route__finances__body__tables__returnedOrders__content__item'>
                                    <p>{rorr.ID}</p>
                                    <p>{rorr.Name}</p>
                                    <p>{rorr.Phone}</p>
                                    <p>{rorr.Price} {returnCurrency(data?.data?.country)}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>}
        {spinner && <Spinner align='center' style={{ marginTop: '0px', width: '28px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
    </div>
}

const ExpensesModal = (props) => {
    const [dropdown, setDropdown] = React.useState(false);
    const [selectedType, setSelectedType] = React.useState({ name: 'Odaberi tip troška', value: -1 });
    const [countryDropdown, setCountryDropdown] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState({ name: 'Odaberi državu', value: -1 });
    const [error, setError] = React.useState(null);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [spinner, setSpinner] = React.useState(true);
    const [data, setData] = React.useState([]);

    const nameRef = React.useRef(null);
    const valueRef = React.useRef(null);

    const createExpense = () => {
        setBtnSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/expenses/addExpense`,
            data: {
                Name: nameRef.current.value,
                Value: valueRef.current.value,
                Type: selectedType.value,
                Country: selectedCountry.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getData();
            } else {
                return setError('Došlo je do greške 32');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/expenses/getAllExpenses`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setError('Došlo je do greške 32');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const deleteExpense = (ID) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/expenses/removeExpense`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getData();
            } else {
                return setError('Došlo je do greške 32');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const returnCurrency = (country) => {
        switch (country) {
            case 'BA':
                return 'KM';
                break;
            case 'RS':
                return 'RSD';
                break;
            case 'ME':
                return 'EUR';
                break;
            case 'HR':
                return 'EUR';
                break;
            case 'MK':
                return 'MKD';
                break;
            case 'XK':
                return 'EUR';
                break;
            case 'AL':
                return 'ALL';
                break;
            case 'HU':
                return 'HUF';
                break;
            case 'BG':
                return 'BGN';
                break;
        }
    }

    React.useEffect(() => {
        getData();
    }, []);

    return <div className='component__expensesModal'>
        <div className='component__expensesModal__wrapper'>
            <div className='component__expensesModal__wrapper__header'>
                <p>Upravljanje troškovima</p>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            <p style={{ marginTop: '20px', fontSize: '15px', background: '#FF9507', color: 'black', padding: '3px', borderRadius: '5px' }}>Unesi vrijednosti u valuti odabrane države, također i za payout!!!</p>
            <div className='component__expensesModal__wrapper__create'>
                <div className='component__expensesModal__wrapper__create__field'>
                    <label>Naziv troška</label>
                    <input ref={nameRef} type='text' />
                </div>
                <div className='component__expensesModal__wrapper__create__field'>
                    <label>Vrijednost troška</label>
                    <input ref={valueRef} type='number' />
                </div>
                <div className='component__expensesModal__wrapper__create__dropdown'>
                    <div onClick={() => setDropdown(d => !d)} className={`component__expensesModal__wrapper__create__dropdown__head ${dropdown ? 'component__expensesModal__wrapper__create__dropdown__head--active' : ''}`}>
                        <p>{selectedType.name}</p>
                    </div>
                    <div className={`component__expensesModal__wrapper__create__dropdown__body ${dropdown ? 'component__expensesModal__wrapper__create__dropdown__body--active' : ''}`}>
                        <p onClick={() => { setSelectedType({ name: 'PLP', value: 'LOW' }); setDropdown(false) }}>Prosječan low payout</p>
                        <p onClick={() => { setSelectedType({ name: 'PFP', value: 'FULL' }); setDropdown(false) }}>Prosječan full payout</p>
                        <p onClick={() => { setSelectedType({ name: 'Po kutiji', value: 'PERBOX' }); setDropdown(false) }}>Po kutiji</p>
                        <p onClick={() => { setSelectedType({ name: 'Po narudzbi', value: 'PERORDER' }); setDropdown(false) }}>Po narudzbi</p>
                    </div>
                </div>
                <div className='component__expensesModal__wrapper__create__dropdown'>
                    <div onClick={() => setCountryDropdown(d => !d)} className={`component__expensesModal__wrapper__create__dropdown__head ${countryDropdown ? 'component__expensesModal__wrapper__create__dropdown__head--active' : ''}`}>
                        <p>{selectedCountry.name}</p>
                    </div>
                    <div className={`component__expensesModal__wrapper__create__dropdown__body ${countryDropdown ? 'component__expensesModal__wrapper__create__dropdown__body--active' : ''}`}>
                        <p onClick={() => { setSelectedCountry({ name: 'Bosna', value: 'BA' }); setCountryDropdown(false) }}>Bosna</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Srbija', value: 'RS' }); setCountryDropdown(false) }}>Srbija</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Crna Gora', value: 'ME' }); setCountryDropdown(false) }}>Crna Gora</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Hrvatska', value: 'HR' }); setCountryDropdown(false) }}>Hrvatska</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Makedonija', value: 'MK' }); setCountryDropdown(false) }}>Makedonija</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Kosovo', value: 'XK' }); setCountryDropdown(false) }}>Kosovo</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Albanija', value: 'AL' }); setCountryDropdown(false) }}>Albanija</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Mađarska', value: 'HU' }); setCountryDropdown(false) }}>Mađarska</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Bugarska', value: 'BG' }); setCountryDropdown(false) }}>Bugarska</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Sve', value: -1 }); setCountryDropdown(false) }}>Sve</p>
                    </div>
                </div>
                <div onClick={() => createExpense()} className='component__expensesModal__wrapper__create__btn'>
                    {!btnSpinner && <p>Kreiraj trošak</p>}
                    {btnSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '14px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                </div>
            </div>

            <div className='component__expensesModal__wrapper__body'>
                {!spinner && <div className='component__expensesModal__wrapper__body__list'>
                    {
                        data?.data?.map(exp => {
                            return <div className='component__expensesModal__wrapper__body__list__row'>
                                <p>{exp.Country}</p>
                                <p>{exp.Name}</p>
                                <p>{(exp.Value).toFixed(2)} {returnCurrency(exp.Country)}</p>
                                <img onClick={() => deleteExpense(exp.ID)} src='images/trash.svg' alt='' />
                            </div>
                        })
                    }
                </div>}
                {spinner && <Spinner align='center' style={{ marginTop: '0px', width: '28px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
            </div>
        </div>
    </div>
}