import React from 'react';
import "./index.scss";
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import moment from 'moment/moment';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig';

export default function Products() {
    const [dropdown, setDropdown] = React.useState(false);
    const [typeDropdown, setTypeDropdown] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState({ name: 'Sve', value: -1 });
    const [selectedType, setSelectedType] = React.useState({ name: 'Sve', value: -1 });
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(true);
    const [offers, setOffers] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState(null);

    const searchRef = React.useRef(null);

    const getOffers = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                filters: [
                    selectedCountry.value !== -1 ? {name: 'Country', op: 'eq', value: selectedCountry.value} : null,
                    selectedType.value !== -1 ? {name: 'Type', op: 'eq', value: selectedType.value} : null,
                    !String(searchValue).includes('ID:') && searchValue ? {name: 'Name', op: 'eq', value: searchValue} : null,
                    String(searchValue).includes('ID:') && searchValue ? {name: 'OfferID', op: 'eq', value: Number(String(searchValue).replace('ID:', ''))} : null
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers(res.data);
            } else {
                return setError('Došlo je do greške 32');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getOffers();
    }, [selectedCountry, selectedType, searchValue]);

    return (
        <div className='route__products'>
            <div className='route__products__header'>
                <div onClick={() => setSearchValue(searchRef.current.value)} className='route__products__header__search'>
                    <input ref={searchRef} type='text' placeholder='Pretraži... (upiši npr. ID:5 za tražit po OfferID)' />
                    <img src='images/header__searchW.svg' alt='' />
                </div>
                <div className='route__products__header__dropdown'>
                    <div onClick={() => setDropdown(d => !d)} className={`route__products__header__dropdown__head ${dropdown ? 'route__products__header__dropdown__head--active' : ''}`}>
                        <p>{selectedCountry.name}</p>
                    </div>
                    <div className={`route__products__header__dropdown__body ${dropdown ? 'route__products__header__dropdown__body--active' : ''}`}>
                        <p onClick={() => { setSelectedCountry({ name: 'Bosna', value: 'BA' }); setDropdown(false) }}>Bosna</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Srbija', value: 'RS' }); setDropdown(false) }}>Srbija</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Crna Gora', value: 'ME' }); setDropdown(false) }}>Crna Gora</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Hrvatska', value: 'HR' }); setDropdown(false) }}>Hrvatska</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Makedonija', value: 'MK' }); setDropdown(false) }}>Makedonija</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Kosovo', value: 'XK' }); setDropdown(false) }}>Kosovo</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Albanija', value: 'AL' }); setDropdown(false) }}>Albanija</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Mađarska', value: 'HU' }); setDropdown(false) }}>Mađarska</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Bugarska', value: 'BG' }); setDropdown(false) }}>Bugarska</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Sve', value: -1 }); setDropdown(false) }}>Sve</p>
                    </div>
                </div>
                <div className='route__products__header__dropdown'>
                    <div onClick={() => setTypeDropdown(d => !d)} className={`route__products__header__dropdown__head ${typeDropdown ? 'route__products__header__dropdown__head--active' : ''}`}>
                        <p>{selectedType.name}</p>
                    </div>
                    <div className={`route__products__header__dropdown__body ${typeDropdown ? 'route__products__header__dropdown__body--active' : ''}`}>
                        <p onClick={() => { setSelectedType({ name: 'Full price', value: 'FULL' }); setTypeDropdown(false) }}>Full price</p>
                        <p onClick={() => { setSelectedType({ name: 'Low price', value: 'LOW' }); setTypeDropdown(false) }}>Low price</p>
                        <p onClick={() => { setSelectedType({ name: 'Sve', value: -1 }); setTypeDropdown(false) }}>Sve</p>
                    </div>
                </div>
                <div onClick={(e) => animateBox(e, <NewOfferModal refresh={getOffers} />)} className='route__products__header__button'>
                    <p>Kreiraj proizvod</p>
                </div>
            </div>

            <div className='route__products__body'>
                <div className='route__products__body__table'>
                    <div className='route__products__body__table__header'>
                        <p>{window.innerWidth > 725 ? 'Offer ID' : 'O.ID'}</p>
                        <p>Naziv</p>
                        <p>Tip</p>
                        <p>Država</p>
                        <p>Cijena</p>
                        <p>Valuta</p>
                        <p>Akcije</p>
                    </div>
                    <div className='route__products__body__table__content'>
                        {!spinner && offers?.data?.map((offer) => {
                            return <div className='route__products__body__table__content__item'>
                                <p>{offer.OfferID}</p>
                                <p>{offer.Name}</p>
                                <p>{offer.Type}</p>
                                <img src={`images/offers/${String(offer.Country).toUpperCase() === 'BA' ? 'pravaBosna.jpg' : String(offer.Country).toUpperCase() === 'RS' ? 'srbistan.png' : String(offer.Country).toUpperCase() === 'ME' ? 'crnagora.png' : String(offer.Country).toUpperCase() === 'HR' ? 'hrvatska.webp' : String(offer.Country).toUpperCase() === 'MK' ? 'makedonija.webp' : String(offer.Country).toUpperCase() === 'SI' ? 'slovenija.webp' : String(offer.Country).toUpperCase() === 'AL' ? 'albanija.webp' : String(offer.Country).toUpperCase() === 'BG' ? 'bugarska.png' : String(offer.Country).toUpperCase() === 'HU' ? 'madjarska.svg' : String(offer.Country).toUpperCase() === 'XK' ? 'kosovo.png' : offer.Country}`} alt='' />
                                <p>{offer.Price}</p>
                                <p>{offer.Currency}</p>
                                <div className='route__products__body__table__content__item__buttons'>
                                    <img onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <EditOfferModal refresh={getOffers} data={offer} />)} src='images/edit.svg' alt='' />
                                    <img onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <DeleteModal offerID={offer.OfferID} ID={offer.ID} refresh={getOffers} />)} src='images/trash.svg' alt='' />
                                </div>
                            </div>
                        })}
                        {spinner && <Spinner align='center' style={{ marginTop: '0px', width: '28px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                        {error && <p style={{ color: '#ffffff', fontFamily: 'Figtree', fontSize: '15px' }}>{error}</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const DeleteModal = (props) => {
    const [deleteSpinner, setDeleteSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);

    const deleteOffer = () => {
        setDeleteSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/removeOffer`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
                props.onClose();
            } else {
                return setError('Došlo je do greške 140');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setDeleteSpinner(false);
        });
    }

    return <div className='deleteModal'>
        <div className='deleteModal__wrapper'>
            <p>Da li želite obrisati OfferID: {props.offerID} ?</p>
            {!deleteSpinner && <div className='deleteModal__wrapper__box'>
                <div onClick={() => deleteOffer()} className='deleteModal__wrapper__box__btn'><p>DA</p></div>
                <div onClick={props.onClose} className='deleteModal__wrapper__box__btn'><p>NE</p></div>
            </div>}
            {deleteSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
            {error && <p>{error}</p>}
        </div>
    </div>
}

const NewOfferModal = (props) => {
    const [dropdown, setDropdown] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState({ name: 'Odaberi', value: -1 });
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [script, setScript] = React.useState(null);
    const [image, setImage] = React.useState(null);

    const nameRef = React.useRef(null);
    const typeRef = React.useRef(null);
    const priceRef = React.useRef(null);
    const currencyRef = React.useRef(null);
    const offerIDRef = React.useRef(null);

    const handleFileChange = (event, type) => {
        if (type === 'script') {
            setScript(event.target.files[0]);
        }
        else {
            setImage(event.target.files[0]);
        }
    };

    const addOffer = () => {
        if (
            !nameRef.current.value ||
            !typeRef.current.value ||
            !priceRef.current.value ||
            !currencyRef.current.value ||
            !offerIDRef.current.value ||
            selectedCountry.value === -1
        ) {
            return setError('Popunite sva polja!');
        }
        else {
            setSpinner(true);
        }

        if (script) {
            const storageRef = ref(storage, `uploads/${script.name}`);

            uploadBytes(storageRef, script)
                .then((snapshot) => {
                    getDownloadURL(storageRef).then((scriptUrl) => {
                        if (image) {
                            const storageRef = ref(storage, `uploads/${image.name}`);
                            uploadBytes(storageRef, image)
                                .then((snapshot) => {
                                    getDownloadURL(storageRef).then((imageUrl) => {
                                        axios({
                                            method: "POST",
                                            url: `${backendModule.backendURL}/offers/addOffer`,
                                            data: {
                                                Name: nameRef.current.value,
                                                Type: String(typeRef.current.value).toUpperCase(),
                                                Country: selectedCountry.value,
                                                Price: Number(priceRef.current.value),
                                                Currency: currencyRef.current.value,
                                                OfferID: offerIDRef.current.value,
                                                ScriptPath: scriptUrl,
                                                ImagePath: imageUrl
                                            },
                                            ...backendModule.axiosConfig
                                        }).then(res => {
                                            if (res.data.status === "ok") {
                                                props.refresh();
                                            } else {
                                                return setError('Došlo je do greške 132');
                                            };
                                        }).catch(() => {
                                            return setError('Server ne reagira...');
                                        }).finally(() => {
                                            setSpinner(false);
                                            setError('Uspješno dodan proizvod za državu: ' + selectedCountry.name);
                                        });
                                    });
                                })
                                .catch((error) => {
                                    setError('Greška pri uploadu slike');
                                });
                        }
                    });
                })
                .catch((error) => {
                    setError('Greška pri uploadu skripte');
                });
        }
    }

    return <div className='newOffer'>
        <div className='newOffer__wrapper'>
            <div className='newOffer__wrapper__header'>
                <p>Kreiraj novi proizvod</p>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            <div className='newOffer__wrapper__body'>
                <div className='newOffer__wrapper__body__top'>
                    <div className='newOffer__wrapper__body__top__field'>
                        <label>Naziv proizvoda</label>
                        <input ref={nameRef} type='text' />
                    </div>
                    <div className='newOffer__wrapper__body__top__field'>
                        <label>Tip (LOW ili FULL)</label>
                        <input ref={typeRef} type='text' />
                    </div>
                    <div className='newOffer__wrapper__body__top__field'>
                        <label>Skripta</label>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(e, 'script')}
                        />
                    </div>
                    <div className='newOffer__wrapper__body__top__field'>
                        <label>Slika</label>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(e, 'image')}
                        />
                    </div>
                </div>
                <div className='newOffer__wrapper__body__middle'>
                    <p>Odaberite državu</p>
                    <div className='route__products__header__dropdown'>
                        <div onClick={() => setDropdown(d => !d)} className={`route__products__header__dropdown__head ${dropdown ? 'route__products__header__dropdown__head--active' : ''}`}>
                            <p>{selectedCountry.name}</p>
                        </div>
                        <div className={`route__products__header__dropdown__body ${dropdown ? 'route__products__header__dropdown__body--active' : ''}`}>
                            <p onClick={() => { setSelectedCountry({ name: 'Bosna', value: 'BA' }); setDropdown(false) }}>Bosna</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Srbija', value: 'RS' }); setDropdown(false) }}>Srbija</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Crna Gora', value: 'ME' }); setDropdown(false) }}>Crna Gora</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Hrvatska', value: 'HR' }); setDropdown(false) }}>Hrvatska</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Makedonija', value: 'MK' }); setDropdown(false) }}>Makedonija</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Kosovo', value: 'XK' }); setDropdown(false) }}>Kosovo</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Albanija', value: 'AL' }); setDropdown(false) }}>Albanija</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Mađarska', value: 'HU' }); setDropdown(false) }}>Mađarska</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Bugarska', value: 'BG' }); setDropdown(false) }}>Bugarska</p>
                        </div>
                    </div>
                </div>
                <div className='newOffer__wrapper__body__bottom'>
                    <div className='newOffer__wrapper__body__bottom__field'>
                        <label>Cijena</label>
                        <input ref={priceRef} type='number' />
                    </div>
                    <div className='newOffer__wrapper__body__bottom__field'>
                        <label>Valuta</label>
                        <input ref={currencyRef} type='text' />
                    </div>
                    <div className='newOffer__wrapper__body__bottom__field'>
                        <label>Offer ID</label>
                        <input ref={offerIDRef} type='number' />
                    </div>
                </div>
                <div onClick={(e) => addOffer()} className='newOffer__wrapper__body__button'>
                    {!spinner && <p>Dodaj proizvod</p>}
                    {spinner && <Spinner align='center' style={{ marginTop: '0px', width: '14px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                </div>
                {error && <p style={{ color: '#ffffff', fontSize: '15px', fontFamily: 'Figtree' }}>{error}</p>}
            </div>
        </div>
    </div>
}

const EditOfferModal = (props) => {
    const [dropdown, setDropdown] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState({ name: props.data?.Country, value: props.data?.Country });
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [script, setScript] = React.useState(null);
    const [image, setImage] = React.useState(null);

    const nameRef = React.useRef(null);
    const typeRef = React.useRef(null);
    const priceRef = React.useRef(null);
    const currencyRef = React.useRef(null);
    const offerIDRef = React.useRef(null);

    const handleFileChange = (event, type) => {
        if (type === 'script') {
            setScript(event.target.files[0]);
        }
        else {
            setImage(event.target.files[0]);
        }
    };

    const editOffer = () => {
        if (
            !nameRef.current.value ||
            !typeRef.current.value ||
            !priceRef.current.value ||
            !currencyRef.current.value ||
            !offerIDRef.current.value ||
            selectedCountry.value === -1
        ) {
            return setError('Popunite sva polja!');
        }
        else {
            setSpinner(true);
        }

        if (script) {
            const storageRef = ref(storage, `uploads/${script.name}`);

            uploadBytes(storageRef, script)
                .then((snapshot) => {
                    getDownloadURL(storageRef).then((scriptUrl) => {
                        if (image) {
                            const storageRef = ref(storage, `uploads/${image.name}`);
                            uploadBytes(storageRef, image)
                                .then((snapshot) => {
                                    getDownloadURL(storageRef).then((imageUrl) => {
                                        axios({
                                            method: "POST",
                                            url: `${backendModule.backendURL}/offers/updateOffer`,
                                            data: {
                                                ID: props.data?.ID,
                                                Name: nameRef.current.value,
                                                Type: String(typeRef.current.value).toUpperCase(),
                                                Country: selectedCountry.value,
                                                Price: Number(priceRef.current.value),
                                                Currency: currencyRef.current.value,
                                                OfferID: offerIDRef.current.value,
                                                ScriptPath: scriptUrl,
                                                ImagePath: imageUrl
                                            },
                                            ...backendModule.axiosConfig
                                        }).then(res => {
                                            if (res.data.status === "ok") {
                                                props.refresh();
                                                props.onClose();
                                            } else {
                                                return setError('Došlo je do greške 132');
                                            };
                                        }).catch(() => {
                                            return setError('Server ne reagira...');
                                        }).finally(() => {
                                            setSpinner(false);
                                            setError('Uspješno uređen proizvod za državu: ' + selectedCountry.name);
                                        });
                                    });
                                })
                                .catch((error) => {
                                    setError('Greška pri uploadu slike');
                                });
                        }
                        else {
                            axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/offers/updateOffer`,
                                data: {
                                    ID: props.data?.ID,
                                    Name: nameRef.current.value,
                                    Type: String(typeRef.current.value).toUpperCase(),
                                    Country: selectedCountry.value,
                                    Price: Number(priceRef.current.value),
                                    Currency: currencyRef.current.value,
                                    OfferID: offerIDRef.current.value,
                                    ScriptPath: scriptUrl,
                                },
                                ...backendModule.axiosConfig
                            }).then(res => {
                                if (res.data.status === "ok") {
                                    props.refresh();
                                    props.onClose();
                                } else {
                                    return setError('Došlo je do greške 132');
                                };
                            }).catch(() => {
                                return setError('Server ne reagira...');
                            }).finally(() => {
                                setSpinner(false);
                                setError('Uspješno uređen proizvod za državu: ' + selectedCountry.name);
                            });
                        }
                    });
                })
                .catch((error) => {
                    setError('Greška pri uploadu skripte');
                });
        }
        else {
            if (image) {
                const storageRef = ref(storage, `uploads/${image.name}`);
                uploadBytes(storageRef, image)
                    .then((snapshot) => {
                        getDownloadURL(storageRef).then((imageUrl) => {
                            axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/offers/updateOffer`,
                                data: {
                                    ID: props.data?.ID,
                                    Name: nameRef.current.value,
                                    Type: String(typeRef.current.value).toUpperCase(),
                                    Country: selectedCountry.value,
                                    Price: Number(priceRef.current.value),
                                    Currency: currencyRef.current.value,
                                    OfferID: offerIDRef.current.value,
                                    ImagePath: imageUrl
                                },
                                ...backendModule.axiosConfig
                            }).then(res => {
                                if (res.data.status === "ok") {
                                    props.refresh();
                                    props.onClose();
                                } else {
                                    return setError('Došlo je do greške 132');
                                };
                            }).catch(() => {
                                return setError('Server ne reagira...');
                            }).finally(() => {
                                setSpinner(false);
                                setError('Uspješno uređen proizvod za državu: ' + selectedCountry.name);
                            });
                        });
                    })
                    .catch((error) => {
                        setError('Greška pri uploadu slike');
                    });
            }
            else {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/offers/updateOffer`,
                    data: {
                        ID: props.data?.ID,
                        Name: nameRef.current.value,
                        Type: String(typeRef.current.value).toUpperCase(),
                        Country: selectedCountry.value,
                        Price: Number(priceRef.current.value),
                        Currency: currencyRef.current.value,
                        OfferID: offerIDRef.current.value,
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        props.refresh();
                        props.onClose();
                    } else {
                        return setError('Došlo je do greške 132');
                    };
                }).catch(() => {
                    return setError('Server ne reagira...');
                }).finally(() => {
                    setSpinner(false);
                    setError('Uspješno uređen proizvod za državu: ' + selectedCountry.name);
                });
            }
        }
    }

    return <div className='newOffer'>
        <div className='newOffer__wrapper'>
            <div className='newOffer__wrapper__header'>
                <p>Uredi proizvod</p>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            <div className='newOffer__wrapper__body'>
                <div className='newOffer__wrapper__body__top'>
                    <div className='newOffer__wrapper__body__top__field'>
                        <label>Naziv proizvoda</label>
                        <input ref={nameRef} type='text' defaultValue={props.data?.Name} />
                    </div>
                    <div className='newOffer__wrapper__body__top__field'>
                        <label>Tip (LOW ili FULL)</label>
                        <input ref={typeRef} type='text' defaultValue={props.data?.Type} />
                    </div>
                    <div className='newOffer__wrapper__body__top__field'>
                        <label>Skripta</label>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(e, 'script')}
                        />
                    </div>
                    <div className='newOffer__wrapper__body__top__field'>
                        <label>Slika</label>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(e, 'image')}
                        />
                    </div>
                </div>
                <div className='newOffer__wrapper__body__middle'>
                    <p>Odaberite državu</p>
                    <div className='route__products__header__dropdown'>
                        <div onClick={() => setDropdown(d => !d)} className={`route__products__header__dropdown__head ${dropdown ? 'route__products__header__dropdown__head--active' : ''}`}>
                            <p>{selectedCountry.name}</p>
                        </div>
                        <div className={`route__products__header__dropdown__body ${dropdown ? 'route__products__header__dropdown__body--active' : ''}`}>
                            <p onClick={() => { setSelectedCountry({ name: 'Bosna', value: 'BA' }); setDropdown(false) }}>Bosna</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Srbija', value: 'RS' }); setDropdown(false) }}>Srbija</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Crna Gora', value: 'ME' }); setDropdown(false) }}>Crna Gora</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Hrvatska', value: 'HR' }); setDropdown(false) }}>Hrvatska</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Makedonija', value: 'MK' }); setDropdown(false) }}>Makedonija</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Kosovo', value: 'XK' }); setDropdown(false) }}>Kosovo</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Albanija', value: 'AL' }); setDropdown(false) }}>Albanija</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Mađarska', value: 'HU' }); setDropdown(false) }}>Mađarska</p>
                            <p onClick={() => { setSelectedCountry({ name: 'Bugarska', value: 'BG' }); setDropdown(false) }}>Bugarska</p>
                        </div>
                    </div>
                </div>
                <div className='newOffer__wrapper__body__bottom'>
                    <div className='newOffer__wrapper__body__bottom__field'>
                        <label>Cijena</label>
                        <input ref={priceRef} type='number' defaultValue={props.data?.Price} />
                    </div>
                    <div className='newOffer__wrapper__body__bottom__field'>
                        <label>Valuta</label>
                        <input ref={currencyRef} type='text' defaultValue={props.data?.Currency} />
                    </div>
                    <div className='newOffer__wrapper__body__bottom__field'>
                        <label>Offer ID</label>
                        <input ref={offerIDRef} type='number' defaultValue={props.data?.OfferID} />
                    </div>
                </div>
                <div onClick={() => editOffer()} className='newOffer__wrapper__body__button'>
                    {!spinner && <p>Uredi proizvod</p>}
                    {spinner && <Spinner align='center' style={{ marginTop: '0px', width: '14px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                </div>
                {error && <p style={{ color: '#ffffff', fontSize: '15px', fontFamily: 'Figtree' }}>{error}</p>}
            </div>
        </div>
    </div>
}