import Login from "./routes/Login";
import React from 'react';
import axios from "axios";
import * as backendModule from "./modules/backendModule";
import Spinner from "./components/Spinner";
import { Worker } from '@react-pdf-viewer/core';
import { Routes, Route, useNavigate } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Kanban from "./routes/Kanban";
import Products from "./routes/Products";
import Offers from "./routes/Offers";
import AtWork from "./routes/AtWork";
import Users from "./routes/Users";
import Publishers from "./routes/Publishers";
import Statistics from "./routes/Statistics";
import Agents from "./routes/Agents";
import Post from "./routes/Post";
import Finances from "./routes/Finances";

function App() {
  const [logged, setLogged] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setUserData(res.data.data.UserInfo);
        setLogged(true);
      }
      else {
        setLogged(false);
      }
    }).catch(() => {
      setLogged(false);
    }).finally(() => {
      setSpinner(false);
    });
  };

  React.useEffect(() => {
    checkLogin();
  }, []);

  return (
    <div className="route__contentWrapper">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker>
      {spinner && <Spinner color='gray' align='center' />}
      {!spinner && <div style={{ gridTemplateColumns: !logged ? '1fr' : logged && window.innerWidth > 1195 ? '230px auto' : '1fr' }} className="route__contentWrapper__body">
        {logged && <Sidebar userData={userData} />}
        <div className="route__contentWrapper__body__routes">
          <Routes>
            {
              logged ?
                <Route path="/" element={<div className="route__contentWrapper__body__routes__home"><p>Dobrodošli u CRM.</p> <p>Sa lijeve strane odaberite što želite raditi</p></div>} />
                : <Route path="/" element={<Login checkLogin={checkLogin} setLogged={setLogged} />} />
            }

            {
              !userData?.Flags?.isPartner ?
                <Route path="/kanban" element={logged ? <Kanban userData={userData} /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
            {
              userData?.Flags?.isAdmin ?
              <Route path="/products" element={logged ? <Products userData={userData} /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
            {
              userData?.Flags?.isAdmin || userData?.Flags?.isPartner ?
              <Route path="/offers" element={logged ? <Offers isAdmin={userData?.Flags?.isAdmin} /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
            {
              userData?.Flags?.isAdmin || userData?.Flags?.isCCmanager ?
              <Route path="/atwork" element={logged ? <AtWork userID={userData?.data?.UserInfo?.ID} /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
            {
              userData?.Flags?.isAdmin ?
              <Route path="/users" element={logged ? <Users /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
            {
              userData?.Flags?.isAdmin ?
              <Route path="/partners" element={logged  ? <Publishers /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
            {
              userData?.Flags?.isAdmin ?
              <Route path="/statistics" element={logged ? <Statistics /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
            {
              !userData?.Flags?.isPartner && !userData?.Flags?.isPost ?
              <Route path="/agents" element={logged ? <Agents userData={userData} /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
            {
              userData?.Flags?.isAdmin || userData?.Flags?.isPost ?
              <Route path="/post" element={logged ? <Post isAdmin={userData?.Flags?.isAdmin} /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
            {
              userData?.Flags?.isAdmin ?
              <Route path="/finances" element={logged ? <Finances /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            }
          </Routes>
        </div>
      </div>}
    </div>
  );
}

export default App;