import React, { useEffect, useState } from 'react';
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment/moment';
import StatusesModal from '../../components/StatusesModal';
import LeadInfoModal from "../../components/LeadInfoModal";
import ExtensionsModal from '../../components/ExtensionsModal';

export default function Kanban(props) {
  const [searchResult, setSearchResult] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [selectedFilter, setSelectedFilter] = React.useState({ name: 'Sve', value: -1 });
  const [dropdown, setDropdown] = React.useState(false);
  const [selectedCountryFilter, setSelectedCountryFilter] = React.useState({ name: 'Sve', value: null });
  const [countryDropdown, setCountryDropdown] = React.useState(false);
  const [extensionsThere, setExtensionsThere] = React.useState(false);
  const [agents, setAgents] = React.useState([]);
  const [mobileFilters, setMobileFilters] = React.useState(false);

  const [statuses, setStatuses] = useState([]);
  const [groupedLeads, setGroupedLeads] = useState({});
  const [visibleLeadsCount, setVisibleLeadsCount] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState({});
  const [dataFilteredByStatus, setDataFilteredByStatus] = React.useState([]);
  const [searchedValue, setSearchedValue] = React.useState(null);

  const searchInputRef = React.useRef();
  const filtersDivRef = React.useRef();

  const search = (type) => {
    if (searchInputRef.current.value === '') {
      setSearchResult([]);
      return;
    }

    setSearchedValue(searchInputRef.current.value);
    setLoading(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        filters: [
          type === 'name' ? { name: 'Name', op: 'like', value: searchInputRef.current.value } : null,
          type === 'hash' ? { name: 'LeadHash', op: 'like', value: searchInputRef.current.value } : null,
          type === 'phone' ? { name: 'Phone', op: 'like', value: searchInputRef.current.value } : null,
        ],
        token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setSearchResult(res.data);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const checkForTodayData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/checkForTodayData`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data?.data?.length > 0) {
        setExtensionsThere(true);
      }
      else {
        setExtensionsThere(false);
      }
    }).catch(() => {

    });
  }

  const getAgents = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllUsers`,
      data: {
        filters: [{ name: "GetLeads", op: "eq", value: true }],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setAgents(res.data);
    }).catch(() => {
      setAgents(backendModule.genericError);
    });
  }

  const fetchData = async () => {
    try {
      setLoading(true);

      const statusesResponse = await axios.post(
        `${backendModule.backendURL}/statuses/getAllStatuses`,
        {},
        backendModule.axiosConfig
      );

      if (statusesResponse.data.status === 'ok') {
        setStatuses(statusesResponse.data.data);

        const grouped = {};
        const visibleCount = {};
        const leadFetchPromises = statusesResponse.data.data.map(async (status) => {
          const leadsResponse = await axios.post(
            `${backendModule.backendURL}/leads/getAllLeads`,
            {
              filters: [
                selectedCountryFilter.value !== null ? { name: 'Country', op: 'eq', value: selectedCountryFilter.value } : null,
                { name: 'Status', op: 'eq', value: status.Numeric },
                !props.userData?.Flags.isAdmin && !props.userData?.Flags.isCCmanager ? { name: 'ResponsiblePerson', op: 'eq', value: props.userData?.ID } : null
              ],
              token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
              offset: 0,
              limit: 20,
            },
            backendModule.axiosConfig
          );
          if (leadsResponse.data.status === 'ok') {
            grouped[status.Numeric] = leadsResponse.data.data;
            visibleCount[status.Numeric] = leadsResponse.data.data.length;
          }
        });

        await Promise.all(leadFetchPromises);
        setGroupedLeads(grouped);
        setVisibleLeadsCount(visibleCount);
      } else {
        setMessage('Došlo je do problema sa učitavanjem podataka.');
      }
    } catch (error) {
      setMessage('Server ne reagira..., 288 linija, kanban');
    } finally {
      setLoading(false);
    }
  };

  const loadMoreLeads = async (statusID) => {
    try {
      setLoadingMore((prev) => ({ ...prev, [statusID]: true }));
      const offset = visibleLeadsCount[statusID];

      const leadsResponse = await axios.post(
        `${backendModule.backendURL}/leads/getAllLeads`,
        {
          filters: [
            selectedCountryFilter.value !== null ? { name: 'Country', op: 'eq', value: selectedCountryFilter.value } : null,
            { name: 'Status', op: 'eq', value: statusID },
            !props.userData?.Flags.isAdmin && !props.userData?.Flags.isCCmanager ? { name: 'ResponsiblePerson', op: 'eq', value: props.userData?.ID } : null
          ],
          token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
          offset: offset,
          limit: 20,
        },
        backendModule.axiosConfig
      );

      if (leadsResponse.data.status === 'ok') {
        setGroupedLeads((prevGroupedLeads) => ({
          ...prevGroupedLeads,
          [statusID]: [...prevGroupedLeads[statusID], ...leadsResponse.data.data],
        }));
        setVisibleLeadsCount((prevVisibleLeadsCount) => ({
          ...prevVisibleLeadsCount,
          [statusID]: prevVisibleLeadsCount[statusID] + leadsResponse.data.data.length,
        }));
      }
    } catch (error) {
      setMessage('Problem sa paginacijom, 326 linija');
    } finally {
      setLoadingMore((prev) => ({ ...prev, [statusID]: false }));
    }
  };

  const filterByStatus = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        filters: [{ name: 'Status', op: 'eq', value: selectedFilter.value }],
        token: 'ikl3478345jdsfjkfgipucbvkld300xjoha'
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setDataFilteredByStatus(res.data);
      }
      else {
        return setMessage('Došlo je do problema..., 333 linija, kanban');
      }
    }).catch(() => {
      return setMessage('Server ne reagira..., 335 linija, kanban');
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    if (selectedFilter.value === -1) {
      fetchData();
    }
  }, [selectedCountryFilter]);

  useEffect(() => {
    if (selectedFilter.value >= 0) {
      filterByStatus();
    }
  }, [selectedFilter]);

  React.useEffect(() => {
    checkForTodayData();
    getAgents();
  }, []);

  React.useEffect(() => {
    if (mobileFilters && filtersDivRef.current) {
      const timeoutID = setTimeout(() => {
        if(filtersDivRef.current){
          filtersDivRef.current.style.overflow = 'visible';
        }
      }, 700);

      return () => clearTimeout(timeoutID)
    } else if (window.innerWidth < 600 && filtersDivRef.current && !mobileFilters){
      filtersDivRef.current.style.overflow = 'hidden';
    }
  }, [mobileFilters]);

  if (loading) {
    return <Spinner align='center' style={{ marginTop: '20px', width: '40px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />;
  }

  if (message) {
    return <p style={{ color: 'white' }}>{message}</p>;
  }

  return (
    <div className='route__kanban'>
      <div className='route__kanban__header'>
        {window.innerWidth < 600 && <div onClick={() => setMobileFilters(f => !f)} className='filters__showFilters'><p>{!mobileFilters ? 'Prikaži opcije' : 'Sakrij opcije'}</p></div>}
        <div ref={filtersDivRef} className={`filters ${mobileFilters ? 'filtersActive' : ''}`}>
         <div className='route__kanban__header__searchBar'>
            <img onClick={() => search('hash')} src='images/header_hashSearch.svg' alt='' />
            <input ref={searchInputRef} type='text' placeholder='Pretraži...' />
            <img onClick={() => search('name')} src='images/header__searchW.svg' alt='' />
            <img onClick={() => search('phone')} src='images/call.svg' alt='' />
          </div>
          <div className='route__kanban__header__dropdown'>
            <div onClick={() => setDropdown(d => !d)} className={`route__kanban__header__dropdown__head ${dropdown ? 'route__kanban__header__dropdown__head--active' : ''}`}>
              <p>{selectedFilter.name}</p>
            </div>
            <div className={`route__kanban__header__dropdown__body ${dropdown ? 'route__kanban__header__dropdown__body--active' : ''}`}>
              {
                statuses.map((status) => {
                  return <p onClick={() => { setSelectedFilter({ name: status.Name, value: status.Numeric }); setDropdown(false); setMobileFilters(false); }}>{status.Name}</p>
                })
              }
              <p onClick={() => { setSelectedFilter({ name: 'Sve', value: -1 }); setDropdown(false); setMobileFilters(false); }}>Sve</p>
            </div>
          </div>
          <div className='route__kanban__header__cdropdown'>
            <div onClick={() => setCountryDropdown(d => !d)} className={`route__kanban__header__cdropdown__head ${countryDropdown ? 'route__kanban__header__cdropdown__head--active' : ''}`}>
              <p>{selectedCountryFilter.name}</p>
            </div>
            <div className={`route__kanban__header__cdropdown__body ${countryDropdown ? 'route__kanban__header__cdropdown__body--active' : ''}`}>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Bosna', value: 'BA' }); setCountryDropdown(false); setMobileFilters(false); }}>Bosna</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Srbija', value: 'RS' }); setCountryDropdown(false); setMobileFilters(false); }}>Srbija</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Crna Gora', value: 'ME' }); setCountryDropdown(false); setMobileFilters(false); }}>Crna Gora</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Hrvatska', value: 'HR' }); setCountryDropdown(false); setMobileFilters(false); }}>Hrvatska</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Makedonija', value: 'MK' }); setCountryDropdown(false); setMobileFilters(false); }}>Makedonija</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Kosovo', value: 'XK' }); setCountryDropdown(false); setMobileFilters(false); }}>Kosovo</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Albanija', value: 'AL' }); setCountryDropdown(false); setMobileFilters(false); }}>Albanija</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Mađarska', value: 'HU' }); setCountryDropdown(false); setMobileFilters(false); }}>Mađarska</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Bugarska', value: 'BG' }); setCountryDropdown(false); setMobileFilters(false); }}>Bugarska</p>
              <p onClick={() => { setSelectedCountryFilter({ name: 'Sve', value: null }); setCountryDropdown(false); setMobileFilters(false); }}>Sve</p>
            </div>
          </div>
          {props.userData?.Flags.isAdmin && <div onClick={(e) => animateBox(e, <StatusesModal />)} className='route__kanban__header__statuses'>
            <p>Upravljanje statusima</p>
          </div>}
          <div onClick={(e) => animateBox(e, <ExtensionsModal userData={props.userData} agents={agents} />)} className='route__kanban__header__statuses'>
            <p>Produženja i pozivi</p>
          </div>
        </div>
      </div>
      {message && <p style={{ color: 'red' }}>{message}</p>}
      {!message && selectedFilter.value === -1 && !searchResult.data && <div
        className='route__kanban__content'
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${statuses?.length}, 1fr)`,
          gap: '10px'
        }}
      >
        {loading ? (
          <Spinner align='center' style={{ marginTop: '20px', width: '40px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />
        ) : (
          statuses.map((status) => (
            <div key={status.Numeric} className='route__kanban__content__column'>
              <div className='route__kanban__content__column__header' style={{ background: status.Color }}>
                <p>{status.Name} ({groupedLeads[status.Numeric]?.length || 0})</p>
              </div>
              <div className='route__kanban__content__column__body'>
                {groupedLeads[status.Numeric] ? (
                  groupedLeads[status.Numeric].map((lead) => {
                    return <div onClick={(e) => animateBox(e, <LeadInfoModal getAll={fetchData} offerID={lead.OfferID} leadID={lead.ID} user={props.userData} agents={agents} />)} key={lead.Numeric} className='route__kanban__content__column__body__lead'>
                      <span>#{lead.ID}</span>
                      <div className='route__kanban__content__column__body__lead__offer'>
                        <strong>{lead.Product}</strong>
                        <strong>{lead.Type}</strong>
                      </div>
                      <p>Partner: {lead.Tag}</p>
                      <p>{moment(lead.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                      <img src={`images/offers/${String(lead.Country).toUpperCase() === 'BA' ? 'pravaBosna.jpg' : String(lead.Country).toUpperCase() === 'RS' ? 'srbistan.png' : String(lead.Country).toUpperCase() === 'ME' ? 'crnagora.png' : String(lead.Country).toUpperCase() === 'HR' ? 'hrvatska.webp' : String(lead.Country).toUpperCase() === 'MK' ? 'makedonija.webp' : String(lead.Country).toUpperCase() === 'SI' ? 'slovenija.webp' : String(lead.Country).toUpperCase() === 'AL' ? 'albanija.webp' : String(lead.Country).toUpperCase() === 'BG' ? 'bugarska.png' : String(lead.Country).toUpperCase() === 'HU' ? 'madjarska.svg' : String(lead.Country).toUpperCase() === 'XK' ? 'kosovo.png' : lead.Country}`} alt='' />
                    </div>
                  })
                ) : (
                  <p style={{ color: '#ffffff', fontSize: '15px' }}>Nema lead-ova za ovaj status</p>
                )}
                {loadingMore[status.Numeric] ? (
                  <Spinner align='center' style={{ marginTop: '20px', width: '40px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />
                ) : (
                  <button className='route__kanban__content__column__body__paginationBtn' onClick={() => loadMoreLeads(status.Numeric)}>Učitaj još</button>
                )}
              </div>
            </div>
          ))
        )}
      </div>}

      {
        selectedFilter.value >= 0 && <div className='route__kanban__content'>
          <div className='route__kanban__content__column'>
            <p style={{ background: statuses.find((status) => status.Numeric === selectedFilter.value).Color }} className='route__kanban__content__column__filtered'>Filterisano: {selectedFilter.name} ({dataFilteredByStatus?.data?.length})</p>
            <div className='route__kanban__content__column__body'>
              {loading ? <Spinner color='white' style={{ width: '28px' }} align='center' /> : null}
              {
                !loading && selectedFilter.value >= 0 && dataFilteredByStatus?.data?.map((lead) => {
                  return <div onClick={(e) => animateBox(e, <LeadInfoModal getAll={fetchData} offerID={lead.OfferID} leadID={lead.ID} user={props.userData} agents={agents} />)} key={lead.Numeric} className='route__kanban__content__column__body__lead'>
                    <span>#{lead.ID}</span>
                    <div className='route__kanban__content__column__body__lead__offer'>
                      <strong>{lead.Product}</strong>
                      <strong>{lead.Type}</strong>
                    </div>
                    <p>Partner: {lead.Tag}</p>
                    <p>Status: {statuses.find((status) => status.Numeric === lead.Status).Name}</p>
                    <p>{moment(lead.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    <img src={`images/offers/${String(lead.Country).toUpperCase() === 'BA' ? 'pravaBosna.jpg' : String(lead.Country).toUpperCase() === 'RS' ? 'srbistan.png' : String(lead.Country).toUpperCase() === 'ME' ? 'crnagora.png' : String(lead.Country).toUpperCase() === 'HR' ? 'hrvatska.webp' : String(lead.Country).toUpperCase() === 'MK' ? 'makedonija.webp' : String(lead.Country).toUpperCase() === 'SI' ? 'slovenija.webp' : lead.Country}`} alt='' />
                  </div>
                })
              }
            </div>
          </div>
        </div>
      }

      {
        !loading && searchResult?.data ? <div className='route__kanban__content'>
          <div className='route__kanban__content__column'>
            <p style={{ background: 'transparent' }} className='route__kanban__content__column__filtered'>Pretraženo: {searchedValue ? searchedValue : null}</p>
            <div className='route__kanban__content__column__body'>
              {loading ? <Spinner color='white' style={{ width: '28px' }} align='center' /> : null}
              {
                !loading && searchResult?.data?.map((lead) => {
                  return <div onClick={(e) => animateBox(e, <LeadInfoModal getAll={fetchData} offerID={lead.OfferID} leadID={lead.ID} user={props.userData} agents={agents} />)} key={lead.Numeric} className='route__kanban__content__column__body__lead'>
                    <span>#{lead.ID}</span>
                    <div className='route__kanban__content__column__body__lead__offer'>
                      <strong>{lead.Product}</strong>
                      <strong>{lead.Type}</strong>
                    </div>
                    <p>Partner: {lead.Tag}</p>
                    <p>Status: {statuses.find((status) => status.Numeric === lead.Status).Name}</p>
                    <p>{moment(lead.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    <img src={`images/offers/${String(lead.Country).toUpperCase() === 'BA' ? 'pravaBosna.jpg' : String(lead.Country).toUpperCase() === 'RS' ? 'srbistan.png' : String(lead.Country).toUpperCase() === 'ME' ? 'crnagora.png' : String(lead.Country).toUpperCase() === 'HR' ? 'hrvatska.webp' : String(lead.Country).toUpperCase() === 'MK' ? 'makedonija.webp' : String(lead.Country).toUpperCase() === 'SI' ? 'slovenija.webp' : lead.Country}`} alt='' />
                  </div>
                })
              }
            </div>
          </div>
        </div> : ''
      }
    </div>
  )
}

const CallAgainModal = (props) => {
  const [error, setError] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [dataSpinner, setDataSpinner] = React.useState(true);

  const checkForTodayData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/checkForTodayData`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setData(res.data);
      }
    }).catch(() => {

    }).finally(() => {
      setDataSpinner(false);
    });
  }

  const removeItem = (ID) => {
    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/removeExtension`,
      data: {
        ID: ID,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        checkForTodayData();
      } else {
        return setError('Došlo je do greške prilikom brisanja upita...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    checkForTodayData();
  }, []);

  return <div className='extensions'>
    <div className='extensions__wrapper'>
      <div className='extensions__wrapper__header'>
        <h1 className='extensions__wrapper__header__h1'>Pozvati danas</h1>
        <img onClick={props.onClose} src='images/head_close.svg' alt='' />
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className='extensions__wrapper__list'>
        {dataSpinner && <Spinner style={{ width: '34px' }} color='white' />}
        {
          !dataSpinner ? data?.data?.map((item) => {
            if (item.Type === 'callAgain') {
              return <div className='extensions__wrapper__list__lead'>
                {!spinner && <img onClick={() => removeItem(item.ID)} src='images/remove.png' alt='' />}
                {spinner && <Spinner style={{ width: '32px' }} color='white' />}
                <p>Ime: {item.LeadName}</p>
                <p>Broj: {item.LeadPhone}</p>
                <p>Proizvod: {item.LeadProduct}</p>
                <p>Država: {item.LeadCountry}</p>
              </div>
            }
          }) : ''
        }
      </div>
    </div>
  </div>
}