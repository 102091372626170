import React from 'react';
import "./index.css";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampActions";

export default function Login(props) {
  const [spinner, setSpinner] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const curDispatch = useDispatch();

  const passRef = React.useRef(null);
  const usernameRef = React.useRef(null);

  const loginHandler = () => {
    if(!usernameRef.current.value || !passRef.current.value){
      return setMessage('Popunite sva polja!');
    }

    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/login`,
      data: {
        username: usernameRef.current.value,
        password: passRef.current.value
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        curDispatch(timestampActions.updateTimestamp());
        props.checkLogin();
        props.setLogged(true);
      } else {
        return setMessage('Prijava nije uspjela');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  return (
    <div className='route__login'>
      <div className='route__login__wrapper'>
        <h1>Izvršite prijavu u CRM</h1>
        <label>Korisničko ime:</label>
        <input ref={usernameRef} type='text' />
        <label>Lozinka:</label>
        <input ref={passRef} type='password' />
        <div onClick={() => loginHandler()}>
          {spinner && <Spinner color='white' style={{ width: '24px' }} align='center' />}
          {!spinner && <p>PRIJAVA</p>}
        </div>
        {message && <p style={{color: 'red'}}>{message}</p>}
      </div>
    </div>
  )
}
