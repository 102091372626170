import React from 'react';
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";

export default function AtWork() {
    const [spinner, setSpinner] = React.useState(false);
    const [deletSpinner, setDeleteSpinner] = React.useState(false);
    const [agents, setAgents] = React.useState([]);
    const [error, setError] = React.useState(null);

    const getAgents = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                filters: [{ name: "AtWork", op: "eq", value: true }],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setAgents(res.data);
            }
        }).catch(() => {
            setError('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const deleteAgent = (ID) => {
        if (ID) {
            setDeleteSpinner(true);
        }
        else {
            return;
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changeAtWork`,
            data: {
                ID: ID,
                AtWork: false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAgents();
            }
        }).catch(() => {
            setError('Greška prilikom slanja podataka...');
        }).finally(() => {
            setDeleteSpinner(false);
        });
    }

    React.useEffect(() => {
        getAgents();
    }, []);

    return (
        <div className='route__atwork'>
            <div className='route__atwork__header'>
                <div onClick={(e) => animateBox(e, <AddWorkerModal refresh={getAgents} />)} className='route__atwork__header__button'>
                    <p>Dodaj u smjenu</p>
                </div>
            </div>

            {!spinner && <div className='route__atwork__table'>
                <div className='route__atwork__table__header'>
                    <p>Ime</p>
                    {window.innerWidth > 710 && <p>Email</p>}
                    {window.innerWidth > 710 && <p>Korisničko ime</p>}
                    <p>Akcije</p>
                </div>
                {
                    !spinner && agents?.data?.map((agent, index) => {
                        return <div className='route__atwork__table__item'>
                            <p>{agent.FirstName} {agent.LastName}</p>
                            {window.innerWidth > 710 && <p>{agent.Email}</p>}
                            {window.innerWidth > 710 && <p>{agent.Username}</p>}
                            <div className='route__atwork__table__item__buttons'>
                                <div onClick={() => deleteAgent(agent.ID)} className='route__atwork__table__item__buttons__btn'>
                                    {!deletSpinner && <p>Ukloni</p>}
                                    {deletSpinner && <Spinner color='white' style={{ width: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>}

            {spinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '42px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    )
}


const AddWorkerModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [agents, setAgents] = React.useState([]);
    const [error, setError] = React.useState(null);

    const getAgents = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                filters: [
                    { name: "GetLeads", op: "eq", value: true },
                    { name: "AtWork", op: "eq", value: false },
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setAgents(res.data);
            }
        }).catch(() => {
            setError('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const sendAgentToWork = (ID) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changeAtWork`,
            data: {
                ID: ID,
                AtWork: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.refresh();
                getAgents();
            }
        }).catch(() => {
            setError('Greška prilikom slanja podataka...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getAgents();
    }, []);

    return <div className='component__addWorker'>
        <div className='component__addWorker__wrapper'>
            <div className='component__addWorker__wrapper__header'>
                <p>Dodaj agenta</p>
                <img onClick={props.onClose} src='/images/closemenu.svg' alt='' />
            </div>

            <div className='component__addWorker__wrapper__content'>
                {!spinner && agents?.data?.map(item => {
                    return <p onClick={() => sendAgentToWork(item.ID)}>{item.FirstName} {item.LastName}</p>
                })}
            </div>

            {spinner && <Spinner color='white' style={{ width: '42px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            {error && <p style={{ color: 'white' }}>{error}</p>}
        </div>
    </div>
}