import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import Spinner from "../../components/Spinner";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig';

export default function Offers(props) {
    const [selectedCountry, setSelectedCountry] = React.useState({ name: 'All', value: -1 });
    const [dropdown, setDropdown] = React.useState(false);
    const [offers, setOffers] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [error, setError] = React.useState(null);

    const getOffers = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                filters: [
                    selectedCountry.value !== -1 ? {name: 'Country', op: 'eq', value: selectedCountry.value} : null,
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers(res.data);
            } else {
                return setError('Došlo je do greške 32');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getOffers();
    }, [selectedCountry]);

    return (
        <div className='route__offers'>
            <div className='route__offers__header'>
                <div className='route__offers__header__dropdown'>
                    <div onClick={() => setDropdown(d => !d)} className={`route__offers__header__dropdown__head ${dropdown ? 'route__offers__header__dropdown__head--active' : ''}`}>
                        <p>{selectedCountry.name}</p>
                    </div>
                    <div className={`route__offers__header__dropdown__body ${dropdown ? 'route__offers__header__dropdown__body--active' : ''}`}>
                        <p onClick={() => { setSelectedCountry({ name: 'Bosnia', value: 'BA' }); setDropdown(false) }}>Bosnia</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Serbia', value: 'RS' }); setDropdown(false) }}>Serbia</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Montenegro', value: 'ME' }); setDropdown(false) }}>Montenegro</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Croatia', value: 'HR' }); setDropdown(false) }}>Croatia</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Macedonia', value: 'MK' }); setDropdown(false) }}>Macedonia</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Kosovo', value: 'XK' }); setDropdown(false) }}>Kosovo</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Albania', value: 'AL' }); setDropdown(false) }}>Albania</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Hungary', value: 'HU' }); setDropdown(false) }}>Hungary</p>
                        <p onClick={() => { setSelectedCountry({ name: 'Bulgaria', value: 'BG' }); setDropdown(false) }}>Bulgaria</p>
                        <p onClick={() => { setSelectedCountry({ name: 'All', value: -1 }); setDropdown(false) }}>All</p>
                    </div>
                </div>
            </div>

            <div className='route__offers__container'>
                {
                    !spinner && !error ? offers?.data?.map((offer) => {
                        return <div onClick={(e) => animateBox(e, <Modal isAdmin={props.isAdmin} offerID={offer.OfferID} offerName={offer.Name} type={offer.Type} country={offer.Country} />)} className='route__offers__container__offer'>
                            <img src={offer.ImagePath} alt='' />
                            <div className='route__offers__container__offer__headline'>
                                <strong>{offer.Name} {offer.Type}</strong>
                                <p>Country: {offer.Country}</p>
                            </div>
                            <span>
                                <p>Price: {offer.Price} {offer.Currency}</p>
                                <img src={`images/offers/${String(offer.Country).toUpperCase() === 'BA' ? 'bosna.png' : String(offer.Country).toUpperCase() === 'RS' ? 'srbistan.png' : String(offer.Country).toUpperCase() === 'ME' ? 'crnagora.png' : String(offer.Country).toUpperCase() === 'HR' ? 'hrvatska.webp' : String(offer.Country).toUpperCase() === 'MK' ? 'makedonija.webp' : String(offer.Country).toUpperCase() === 'SI' ? 'slovenija.webp' : String(offer.Country).toUpperCase() === 'AL' ? 'albanija.webp' : String(offer.Country).toUpperCase() === 'BG' ? 'bugarska.png' : String(offer.Country).toUpperCase() === 'HU' ? 'madjarska.svg' : String(offer.Country).toUpperCase() === 'XK' ? 'kosovo.png' : offer.Country}`} alt='' />
                            </span>
                        </div>
                    }) : null
                }
                {spinner && <Spinner align='center' style={{ marginTop: '0px', width: '28px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
            </div>
        </div>
    )
}

const Modal = (props) => {
    const [logged, setLogged] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const apiKeyRef = React.useRef(null);
    const [file, setFile] = React.useState(null);
    const [uploadSpinner, setUploadSpinner] = React.useState(false);
    const [uploadMsg, setUploadMsg] = React.useState(null);
    const [landers, setLanders] = React.useState([]);
    const [landersError, setLandersError] = React.useState(null);
    const [landersSpinner, setLandersSpinner] = React.useState(true);

    const getPayout = () => {
        if (!apiKeyRef?.current.value) {
            return;
        }
        else {
            setSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/getAllPublishers`,
            data: {
                filters: [{ name: 'ApiKey', op: 'eq', value: apiKeyRef?.current.value }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data.data[0].Publisher?.Payouts);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
            setLogged(true);
        });
    }

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (file) {
            setUploadSpinner(true);
            const storageRef = ref(storage, `uploads/${file.name}`);

            uploadBytes(storageRef, file)
                .then((snapshot) => {
                    setUploadMsg('Upload uspješan');

                    getDownloadURL(storageRef).then((url) => {
                        axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/landersLinks/addLanderLink`,
                            data: {
                                OfferID: props.offerID,
                                DownloadURL: url
                            },
                            ...backendModule.axiosConfig
                        }).then(res => {
                            if (res.data.status === "ok") {
                                getLanders();
                            } else {
                                return setUploadMsg('Greška pri uploadu');
                            };
                        }).catch(() => {
                            return setUploadMsg('Server ne reagira...');
                        }).finally(() => {
                            setUploadSpinner(false);
                        });
                    });
                })
                .catch((error) => {
                    setUploadMsg('Greška pri uploadu');
                });
        }
    };

    const getLanders = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/landersLinks/getAllLanders`,
            data: {
                OfferID: props.offerID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setLanders(res.data);
            } else {
                return setLandersError('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setLandersError('Server ne reagira...');
        }).finally(() => {
            setLandersSpinner(false);
        });
    }

    const deleteLander = (ID) => {
        setLandersSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/landersLinks/removeLanderLink`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getLanders();
            } else {
                return setLandersError('Došlo je do greške prilikom brisanja podataka...');
            };
        }).catch(() => {
            return setLandersError('Server ne reagira...');
        }).finally(() => {

        });
    }

    React.useEffect(() => {
        getLanders();
        window.scrollTo(0, 0);
    }, []);

    return <div className='modal'>
        <div className='modal__wrapper'>
            <div className='modal__wrapper__head'>
                <h1>{props.offerName} {props.type} {props.country}</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>

            {!logged && !spinner ? <div className='modal__wrapper__apiKey'>
                {!props.isAdmin && <p>Please insert your API Key</p>}
                {!props.isAdmin && <input ref={apiKeyRef} type='text' />}
                <div className='modal__wrapper__apiKey__btns'>
                    {!props.isAdmin && <button onClick={() => getPayout()}>Enter</button>}
                    {props.isAdmin && <input type="file" onChange={handleFileChange} />}
                    {props.isAdmin && !uploadSpinner ? <button onClick={handleUpload}>Upload</button> : null}
                    {props.isAdmin && uploadSpinner ? <Spinner color='#ffffff' style={{ width: '24px' }} /> : null}
                </div>
                {uploadMsg && props.isAdmin ? <p style={{ color: '#ffffff', fontSize: '15px', fontFamily: 'Figtree' }}>{uploadMsg}</p> : null}

                {props.isAdmin && <div className='modal__wrapper__apiKey__landers'>
                    {landersSpinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '40px' }} />}
                    {!landersSpinner && landers?.data?.map((lander) => {
                        return <span><a href={lander.DownloadURL}>Download Landing Page {lander.ID}</a> <img onClick={() => deleteLander(lander.ID)} src='images/trash.svg' alt='' /></span>
                    })}
                </div>}
            </div> : null}

            {spinner && <Spinner align='center' color='white' />}

            {logged && <div className='modal__wrapper__details'>
                <span><p>Your payout:</p> <p>${JSON.parse(data)?.find(payout => payout.offerID == props.offerID)?.payout}</p></span>
                <span><p>Offer ID:</p> <p>{props.offerID}</p></span>
                <div className='modal__wrapper__apiKey__landers'>
                    {landersSpinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '40px' }} />}
                    {!landersSpinner && landers?.data?.map((lander) => {
                        return <span><a href={lander.DownloadURL}>Download Landing Page {lander.ID}</a></span>
                    })}
                </div>
            </div>}
        </div>
    </div>
}