import React from 'react';
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment/moment';

export default function Publishers() {
    const [spinner, setSpinner] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [message, setMessage] = React.useState(null);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/getAllPublishers`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div className='route__publishers'>
            <div className='route__publishers__header'>
                <div onClick={(e) => animateBox(e, <CreatePublisherModal getData={getData} />)} className='route__publishers__header__button'>
                    <p>Novi partner</p>
                </div>
            </div>

            <div className='route__publishers__table'>
                <div className='route__publishers__table__header'>
                    <strong>ID</strong>
                    <strong>Naziv</strong>
                    <strong>Tag</strong>
                    <strong>Balans</strong>
                    <strong>Datum kreiranja</strong>
                    <strong>Akcije</strong>
                </div>
                <div className='route__publishers__table__content'>
                    {
                        !spinner && data?.data?.map((item, index) => {
                            return <div className='route__publishers__table__content__row'>
                                <p>{item.ID}</p>
                                <p>{item.Name}</p>
                                <p>{item.Tag}</p>
                                <p>${item.Balance}</p>
                                <p>{moment(item.createdAt).format('DD.MM.YYYY.')}</p>
                                <div
                                    className='route__publishers__table__content__row__buttons'>
                                    <img onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <PublisherStats refresh={getData} data={item} publisherID={item?.ID} publisherName={item?.Name} publisherTag={item?.Tag} />)}
                                        src='images/zoom.svg' alt='' />
                                    <img onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <EditPublisher getData={getData} data={item} />)} src='images/edit.svg' alt='' />
                                    <img onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <DeleteModal name={item.Name} refresh={getData} ID={item.ID} />)} src='images/trash.svg' alt='' />
                                </div>
                            </div>
                        })
                    }
                    {spinner && <Spinner style={{ width: '32px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                    {message && <p style={{ color: '#9b0b0b' }}>{message}</p>}
                </div>
            </div>
        </div>
    )
}

const DeleteModal = (props) => {
    const [deleteSpinner, setDeleteSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);

    const deleteOffer = () => {
        setDeleteSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/removePublisher`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
                props.onClose();
            } else {
                return setError('Došlo je do greške 140');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setDeleteSpinner(false);
        });
    }

    return <div className='deleteModal'>
        <div className='deleteModal__wrapper'>
            <p>Da li želite obrisati {props.name} ?</p>
            {!deleteSpinner && <div className='deleteModal__wrapper__box'>
                <div onClick={() => deleteOffer()} className='deleteModal__wrapper__box__btn'><p>DA</p></div>
                <div onClick={props.onClose} className='deleteModal__wrapper__box__btn'><p>NE</p></div>
            </div>}
            {deleteSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
            {error && <p>{error}</p>}
        </div>
    </div>
}

const PublisherStats = (props) => {

    const generateAPIdoc = async () => {
        try {
            const response = await fetch('/apiDocTemplate.txt');
            const templateText = await response.text();

            const filledText = templateText
                .replaceAll('{{ApiKey}}', props.data?.ApiKey)
                .replaceAll('{{Tag}}', props.data?.Tag)
                .replaceAll('{{PartnerID}}', props.data?.ID);

            const blob = new Blob([filledText], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${props.data?.Name} API documentation.txt`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Greška prilikom učitavanja ili generiranja dokumenta:', error);
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <div className='route__publishers__statsModal'>
        <div className='route__publishers__statsModal__wrapper'>
            <div className='route__publishers__statsModal__wrapper__head'>
                <h1>{props.publisherName}</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            <div className='route__publishers__statsModal__wrapper__body'>
                <div className='route__publishers__statsModal__wrapper__body__actions'>
                    <div
                        onClick={(e) => animateBox(e, <ExportModal publisherID={props.data?.ID} />)}
                        className='route__publishers__statsModal__wrapper__body__actions__button'>
                        <p>Export</p>
                    </div>
                    <div
                        onClick={(e) => animateBox(e, <BalanceModal refresh={props.refresh} publisherID={props.data?.ID} />)}
                        className='route__publishers__statsModal__wrapper__body__actions__button'>
                        <p>Balans</p>
                    </div>
                    <div
                        onClick={generateAPIdoc}
                        className='route__publishers__statsModal__wrapper__body__actions__button'>
                        <p>Dokumentacija</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const BalanceModal = (props) => {
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const depositAmountRef = React.useRef(null);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/getBalance`,
            data: {
                ID: props.publisherID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const makePayout = () => {
        setBtnSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/makePayout`,
            data: {
                ID: props.publisherID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getData();
                props.refresh()
            } else {
                return setMessage('Došlo je do greške prilikom slanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    const makeDeposit = () => {
        if (!depositAmountRef.current.value) {
            return setMessage('Unesi iznos depozita');
        }else{
            setBtnSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/makeDeposit`,
            data: {
                ID: props.publisherID,
                Deposit: depositAmountRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getData();
                props.refresh()
            } else {
                return setMessage('Došlo je do greške prilikom slanja podataka...');
            };
        }).catch(() => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    React.useEffect(() => {
        getData();
    }, []);

    return <div className='component__balanceModal'>
        <div className='component__balanceModal__wrapper'>
            <div className='component__balanceModal__wrapper__header'>
                <h1>Balans</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            {!spinner && !message ? <div className='component__balanceModal__wrapper__body'>
                <p>${data.data}</p>
                <div onClick={() => { makePayout() }} className='component__balanceModal__wrapper__body__button'>
                    {!btnSpinner && <p>Isplati</p>}
                    {btnSpinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '22px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
                </div>
                <div className='component__balanceModal__wrapper__body__deposit'>
                    <input type='number' placeholder='Iznos depozita' ref={depositAmountRef} />
                    <div onClick={() => { makeDeposit() }} className='component__balanceModal__wrapper__body__deposit__button'>
                        {!btnSpinner && <p>Uplati</p>}
                        {btnSpinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '22px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
                    </div>
                </div>
            </div> : null}
            {spinner && <Spinner color='white' style={{ width: '32px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            {message && <p style={{ color: 'white', padding: '10px' }}>{message}</p>}
        </div>
    </div>
}

const ExportModal = (props) => {
    const [message, setMessage] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [type, setType] = React.useState(null);
    const startDateRef = React.useRef(null);
    const endDateRef = React.useRef(null);
    const publisherIDref = React.useRef(null);
    const webmasterIDref = React.useRef(null);

    const exportLeads = (type, period, startDate, endDate, publisherID, webmasterID) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/exportData`,
            data: {
                PartnerID: props.publisherID,
                Type: type,
                Period: period ? period : null,
                startDate: startDate ? startDate : null,
                endDate: endDate ? endDate : null,
                PublisherID: publisherID,
                WebmasterID: webmasterID
            },
            responseType: 'blob',
            ...backendModule.axiosConfig
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${period}-PartnerID-${props.publisherID}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            return setMessage('Greška prilikom dohvatanja podataka...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='component__exportModal'>
        <div className='component__exportModal__wrapper'>
            <div className='component__exportModal__wrapper__header'>
                <h1>Export</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            {!spinner && !message ? <div className='component__exportModal__wrapper__body'>
                <div onClick={() => exportLeads('LOW', 'today')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT DANAS LOW</p>
                </div>
                <div onClick={() => exportLeads('LOW', 'yesterday')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT JUČER LOW</p>
                </div>
                <div onClick={() => exportLeads('LOW', 'sevenDays')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT 7 DANA LOW</p>
                </div>
                <div onClick={() => exportLeads('LOW', 'lastMonth')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT MJESEC LOW</p>
                </div>
                <div onClick={() => exportLeads('FULL', 'today')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT DANAS FULL</p>
                </div>
                <div onClick={() => exportLeads('FULL', 'yesterday')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT JUČER FULL</p>
                </div>
                <div onClick={() => exportLeads('FULL', 'sevenDays')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT 7 DANA FULL</p>
                </div>
                <div onClick={() => exportLeads('FULL', 'lastMonth')} className='component__exportModal__wrapper__body__button'>
                    <p>EXPORT MJESEC FULL</p>
                </div>
            </div> : null}
            {!spinner && !message ? <div className='component__exportModal__wrapper__period'>
                <div className='component__exportModal__wrapper__period__top'>
                    <p>OD:</p>
                    <input ref={startDateRef} type='date' />
                    <p>DO:</p>
                    <input ref={endDateRef} type='date' />
                </div>
                <div className='component__exportModal__wrapper__period__bottom'>
                    <span onClick={() => setType('FULL')} style={{ background: type === 'FULL' ? '#F3F3F3' : 'transparent', color: type === 'FULL' ? 'rgb(19, 33, 60)' : '#F3F3F3' }}>FULL</span>
                    <p>ili</p>
                    <span onClick={() => setType('LOW')} style={{ background: type === 'LOW' ? '#F3F3F3' : 'transparent', color: type === 'LOW' ? 'rgb(19, 33, 60)' : '#F3F3F3' }}>LOW</span>
                    <input type='text' ref={publisherIDref} placeholder='PublisherID' />
                    <input type='text' ref={webmasterIDref} placeholder='WebmasterID' />
                    <button onClick={() => exportLeads(type, null, startDateRef.current.value, endDateRef.current.value, publisherIDref.current.value, webmasterIDref.current.value)}>EXPORT</button>
                </div>
            </div> : null}
            {spinner && <Spinner style={{ width: '34px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            {message && <p style={{ color: 'white' }}>{message}</p>}
        </div>
    </div>
}

const CreatePublisherModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [payouts, setPayouts] = React.useState([]);
    const [offers, setOffers] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [getSpinner, setGetSpinner] = React.useState(true);

    const nameRef = React.useRef(null);
    const tagRef = React.useRef(null);

    const getOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers(res.data);
            } else {
                return setError('Došlo je do greške 32');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setGetSpinner(false);
        });
    }


    const createPublisher = () => {
        if (!nameRef.current.value || !tagRef.current.value) return setMessage('Popunite sva polja');

        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/addPublisher`,
            data: {
                Name: nameRef.current.value,
                Tag: tagRef.current.value,
                Payouts: payouts
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.getData();
                props.onClose();
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch((e) => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const handlePayouts = (offerID, value) => {
        let thatOffer = payouts.find(function (obj) {
            return obj.offerID === offerID;
        });
        if (thatOffer) {
            payouts.map(item => {
                if (item.offerID === offerID) {
                    item.payout = value;
                }
            })
        }
        else {
            setPayouts(prev => [...prev, { offerID: offerID, payout: value }]);
        }
    }

    React.useEffect(() => {
        getOffers();
    }, []);

    return <div className='component__createModal'>
        <div className='component__createModal__wrapper'>
            <div className='component__createModal__wrapper__header'>
                <h1>Novi partner</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            {!getSpinner && <div className='component__createModal__wrapper__body'>
                <div className='component__createModal__wrapper__body__top'>
                    <div className='component__createModal__wrapper__body__top__field'>
                        <label>Naziv</label>
                        <input ref={nameRef} type='text' />
                    </div>
                    <div className='component__createModal__wrapper__body__top__field'>
                        <label>Tag</label>
                        <input ref={tagRef} type='text' />
                    </div>
                </div>
                <div className='component__createModal__wrapper__body__middle'>
                    {
                        offers.data?.map(offer => {
                            return <div className='component__createModal__wrapper__body__middle__field'>
                                <label>{offer.Type} - {offer.Name} - {offer.Country}</label>
                                <input onChange={(e) => handlePayouts(offer.OfferID, e.currentTarget.value)} type='number' />
                            </div>
                        })
                    }
                </div>
                <div onClick={() => createPublisher()} className='component__createModal__wrapper__body__button'>
                    {!spinner && <p>Kreiraj</p>}
                    {spinner && <Spinner color='white' style={{ width: '24px' }} />}
                </div>
                <p className='component__createModal__wrapper__body__message'>{message}</p>
            </div>}
            {getSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '28px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
        </div>
    </div>
}

const EditPublisher = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [payouts, setPayouts] = React.useState(JSON.parse(props.data?.Payouts));
    const [offers, setOffers] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [getSpinner, setGetSpinner] = React.useState(true);

    const nameRef = React.useRef(null);
    const tagRef = React.useRef(null);

    const getOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers(res.data);
            } else {
                return setError('Došlo je do greške 32');
            };
        }).catch(() => {
            return setError('Server ne reagira...');
        }).finally(() => {
            setGetSpinner(false);
        });
    }


    const savePublisher = () => {
        if (!nameRef.current.value || !tagRef.current.value) return setMessage('Popunite sva polja');

        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/publishers/updatePublisher`,
            data: {
                ID: props.data?.ID,
                Name: nameRef.current.value,
                Tag: tagRef.current.value,
                Payouts: payouts
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.getData();
                props.onClose();
            } else {
                return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
            };
        }).catch((e) => {
            return setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const handlePayouts = (offerID, value) => {
        let thatOffer = payouts.find(function (obj) {
            return obj.offerID === offerID;
        });
        if (thatOffer) {
            payouts.map(item => {
                if (item.offerID === offerID) {
                    item.payout = value;
                }
            })
        }
        else {
            setPayouts(prev => [...prev, { offerID: offerID, payout: value }]);
        }
    }

    React.useEffect(() => {
        getOffers();
    }, []);

    return <div className='component__createModal'>
        <div className='component__createModal__wrapper'>
            <div className='component__createModal__wrapper__header'>
                <h1>{props.data.Name}</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>
            {!getSpinner && <div className='component__createModal__wrapper__body'>
                <div className='component__createModal__wrapper__body__top'>
                    <div className='component__createModal__wrapper__body__top__field'>
                        <label style={{ color: '#ffffff', fontFamily: 'Figtree', fontSize: '15px' }}>Naziv</label>
                        <input ref={nameRef} type='text' defaultValue={props.data?.Name} />
                    </div>
                    <div className='component__createModal__wrapper__body__top__field'>
                        <label style={{ color: '#ffffff', fontFamily: 'Figtree', fontSize: '15px' }}>Tag</label>
                        <input ref={tagRef} type='text' defaultValue={props.data?.Tag} />
                    </div>
                </div>
                <div className='component__createModal__wrapper__body__middle'>
                    {
                        offers.data?.map(offer => {
                            return <div className='component__createModal__wrapper__body__middle__field'>
                                <label>{offer.Type} - {offer.Name} - {offer.Country}</label>
                                <input onChange={(e) => handlePayouts(offer.OfferID, e.currentTarget.value)} type='number' defaultValue={JSON.parse(props.data?.Payouts).find(po => po.offerID == offer.OfferID) ? JSON.parse(props.data?.Payouts).find(po => po.offerID == offer.OfferID).payout : 0} />
                            </div>
                        })
                    }
                </div>
                <div onClick={() => savePublisher()} className='component__createModal__wrapper__body__button'>
                    {!spinner && <p>Uredi</p>}
                    {spinner && <Spinner color='white' style={{ width: '24px' }} />}
                </div>
                <p className='component__createModal__wrapper__body__message'>{message}</p>
            </div>}
            {getSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '28px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
        </div>
    </div>
}