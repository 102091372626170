import React from 'react';
import "./index.scss";
import Spinner from '../../components/Spinner';
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment/moment';

export default function Post(props) {
    const [data, setData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [lengthSpinner, setLengthSpinner] = React.useState(true);
    const [message, setMessage] = React.useState(null);
    const [dropdown, setDropdown] = React.useState(false);
    const [country, setCountry] = React.useState({ name: 'Bosna', value: 'BA' });
    const [lengths, setLengths] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState(null);
    const [date, setDate] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState(0);
    const [allTime, setAllTime] = React.useState(false);

    const searchRef = React.useRef(null);

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/getAllOrders`,
            data: {
                filters: {
                    Country: country.value,
                    Status: selectedStatus,
                    Name: searchValue,
                    createdAt: date,
                    allTime: allTime
                }
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setDate(today);
    }, []);

    React.useEffect(() => {
        if (date) {
            getData();
        }
    }, [country, date, searchValue, selectedStatus, allTime]);

    return (
        <div className='route__post'>
            <div className='route__post__header'>
                <div className='route__post__header__dropdown'>
                    <div onClick={() => setDropdown(d => !d)} className={`route__post__header__dropdown__head ${dropdown ? 'route__post__header__dropdown__head--active' : ''}`}>
                        <p>{country.name}</p>
                    </div>
                    <div className={`route__post__header__dropdown__body ${dropdown ? 'route__post__header__dropdown__body--active' : ''}`}>
                        <p onClick={() => { setCountry({ name: 'Bosna', value: 'BA' }); setDropdown(false) }}>Bosna</p>
                        <p onClick={() => { setCountry({ name: 'Srbija', value: 'RS' }); setDropdown(false) }}>Srbija</p>
                        <p onClick={() => { setCountry({ name: 'Crna Gora', value: 'ME' }); setDropdown(false) }}>Crna Gora</p>
                        <p onClick={() => { setCountry({ name: 'Hrvatska', value: 'HR' }); setDropdown(false) }}>Hrvatska</p>
                        <p onClick={() => { setCountry({ name: 'Makedonija', value: 'MK' }); setDropdown(false) }}>Makedonija</p>
                        <p onClick={() => { setCountry({ name: 'Kosovo', value: 'XK' }); setDropdown(false) }}>Kosovo</p>
                        <p onClick={() => { setCountry({ name: 'Albanija', value: 'AL' }); setDropdown(false) }}>Albanija</p>
                        <p onClick={() => { setCountry({ name: 'Mađarska', value: 'HU' }); setDropdown(false) }}>Mađarska</p>
                        <p onClick={() => { setCountry({ name: 'Bugarska', value: 'BG' }); setDropdown(false) }}>Bugarska</p>
                    </div>
                </div>
                <div onClick={() => setSearchValue(searchRef.current.value)} className='route__post__header__search'>
                    <input ref={searchRef} type='text' placeholder='Pretraži po imenu...' />
                    <img src='images/header__searchW.svg' alt='' />
                </div>
                {!allTime && <input onChange={(e) => setDate(e.target.value)} type='date' value={date} />}
                <div onClick={() => setAllTime(a => !a)} className='route__post__header__button'>
                    <p>{!allTime ? 'Ukupni prikaz' : 'Poništi'}</p>
                </div>
            </div>

            {!spinner && !message ? <div className='route__post__body'>
                <div className='route__post__body__kpis'>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            <p>Na čekanju</p>
                            <p>{data?.data?.statusCounts?.status0}</p>
                        </span>
                        <img src='images/order.png' alt='' />
                    </div>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            <p>U isporuci</p>
                            <p>{data?.data?.statusCounts?.status1}</p>
                        </span>
                        <img src='images/uisporuci.png' alt='' />
                    </div>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            <p>Isporučenih</p>
                            <p>{data?.data?.statusCounts?.status3}</p>
                        </span>
                        <img src='images/isporucenih.png' alt='' />
                    </div>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            <p>Vraćenih</p>
                            <p>{data?.data?.statusCounts?.status2}</p>
                        </span>
                        <img src='images/vracenih.png' alt='' />
                    </div>
                    <div className='route__post__body__kpis__kpi'>
                        <span>
                            {window.innerWidth > 1310 ? <p>Procenat povrata</p> : <p>P. povrata</p>}
                            <p>{data?.data?.returnPercentage} %</p>
                        </span>
                        <img src='images/povrat.png' alt='' />
                    </div>
                </div>

                <div className='route__post__body__statusFilter'>
                    <div onClick={() => setSelectedStatus(0)} className='route__post__body__statusFilter__filter'>
                        <p>Na čekanju</p>
                        <div style={{ background: selectedStatus === 0 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                    <div onClick={() => setSelectedStatus(1)} className='route__post__body__statusFilter__filter'>
                        <p>U isporuci</p>
                        <div style={{ background: selectedStatus === 1 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                    <div onClick={() => setSelectedStatus(3)} className='route__post__body__statusFilter__filter'>
                        <p>Isporučeni</p>
                        <div style={{ background: selectedStatus === 3 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                    <div onClick={() => setSelectedStatus(2)} className='route__post__body__statusFilter__filter'>
                        <p>Vraćeni</p>
                        <div style={{ background: selectedStatus === 2 ? 'rgba(142, 134, 252, 0.904)' : 'transparent' }} className='route__post__body__statusFilter__filter__line'></div>
                    </div>
                </div>

                <div className='route__post__body__table'>
                    {
                        data?.data?.orders?.map(item => {
                            return <div onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode.parentNode }, <OrderDetails isAdmin={props.isAdmin} data={item} orderID={item.ID} refresh={getData} />)} className='route__post__body__table__row'>
                                <p>{item.ID}</p>
                                <p>{item.Name}</p>
                                <p>{item.Product}</p>
                                {window.innerWidth > 492 && <p>{item.Price}</p>}
                                <p>{moment(item.createdAt).format('DD.MM.YYYY.')}</p>
                            </div>
                        })
                    }
                </div>
            </div> : null}
            {spinner && <Spinner color='#ffffff' style={{ width: '42px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
            {message && <p style={{ color: 'white' }}>{message}</p>}
        </div>
    )
}


const OrderDetails = (props) => {
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [sendSpinner, setSendSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const deleteOrder = (ID) => {
        setBtnSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/removeOrder`,
            data: {
                token: "ikl3478345jdsfjkfgipucbvkld300xjoha",
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
                props.onClose();
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setBtnSpinner(false);
            props.onClose();
        });
    }

    const changeStatus = (ID, Status) => {
        setSendSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/orders/changeStatus`,
            data: {
                ID: ID,
                Status: Status
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
                props.onClose();
            }
            else {
                setMessage('Greška prilikom dohvatanja podataka...');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setSendSpinner(false);
        });
    }

    return <div className='component__detailsModal'>
        <div className='component__detailsModal__wrapper'>
            <div className='component__detailsModal__wrapper__header'>
                <h1 className='component__detailsModal__wrapper__header__h1'>Detalji paketa ID - {props.orderID}</h1>
                <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
            </div>

            {!message ? <div className='component__detailsModal__wrapper__body'>
                <div className='component__detailsModal__wrapper__body__block'>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Ime kupca</label>
                        <p>{props.data?.Name}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Broj telefona</label>
                        <p>{props.data?.Phone}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Grad</label>
                        <p>{props.data?.City}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Poštanski broj</label>
                        <p>{props.data?.ZIP}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Ulica</label>
                        <p>{props.data?.Street}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Količina</label>
                        <p>{props.data?.Quantity}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Cijena</label>
                        <p>{props.data?.Price}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Proizvod</label>
                        <p>{props.data?.Product}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Država</label>
                        <p>{props.data?.Country}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__block__field'>
                        <label>Napomena</label>
                        <p>{props.data?.Note}</p>
                    </div>
                </div>
                <div className='component__detailsModal__wrapper__body__block'>
                    <p>Lead ID: {props.data?.LeadID}</p>
                    <p>Tip: {props.data?.Type}</p>
                    <p>Partner ID: {props.data?.PartnerID}</p>
                    <p>Status: {props.data?.Status === 0 ? 'Na čekanju' : props.data?.Status === 1 ? 'Paket poslan' : props.data?.Status === 3 ? 'Paket isporučen' : 'Paket vraćen'}</p>
                </div>
                <div className='component__detailsModal__wrapper__body__actions'>
                    {props.isAdmin && <div onClick={() => deleteOrder(props.data?.ID)} style={{ background: 'rgb(159 27 27)', color: '#ffffff', border: 'none' }} className='component__detailsModal__wrapper__body__actions__button'>
                        {!btnSpinner && <p>Obriši paket</p>}
                        {btnSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                    </div>}
                    {props.data?.Status === 0 && <div onClick={() => changeStatus(props.data?.ID, 1)} className='component__detailsModal__wrapper__body__actions__button'>
                        {!sendSpinner && <p>Pošalji paket</p>}
                        {sendSpinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '22px' }} />}
                    </div>}

                    {props.data?.Status === 1 && <div onClick={() => changeStatus(props.data?.ID, 2)} className='component__detailsModal__wrapper__body__actions__button'>
                        {!sendSpinner && <p>Vraćen</p>}
                        {sendSpinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '22px' }} />}
                    </div>}
                    {props.data?.Status === 1 && <div onClick={() => changeStatus(props.data?.ID, 3)} className='component__detailsModal__wrapper__body__actions__button'>
                        {!sendSpinner && <p>Isporučen</p>}
                        {sendSpinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '22px' }} />}
                    </div>}

                    {props.data?.Status === 2 && <div onClick={() => changeStatus(props.data?.ID, 0)} className='component__detailsModal__wrapper__body__actions__button'>
                        {!sendSpinner && <p>Vrati na čekanje</p>}
                        {sendSpinner && <Spinner color='rgb(19, 33, 60)' style={{ width: '22px' }} />}
                    </div>}
                </div>
            </div> : null}
            {message && <p style={{ color: 'white' }}>{message}</p>}
        </div>
    </div>
}