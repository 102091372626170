import React from 'react';
import "./index.scss";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment/moment';
import LeadInfoModal from '../LeadInfoModal';

export default function ExtensionsModal(props) {
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [dataSpinner, setDataSpinner] = React.useState(true);
    const [isSold, setIsSold] = React.useState([{ ID: 'n', isSold: 0 }]);
    const [deleteSpinner, setDeleteSpinner] = React.useState(false);

    const checkForTodayData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/extensions/checkForTodayData`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setData(res.data);
            }
        }).catch(() => {

        }).finally(() => {
            setDataSpinner(false);
        });
    }

    const handleCheckboxChange = (e, ID) => {
        const isChecked = e.target.checked;
        setIsSold([{ ID: ID, isSold: isChecked ? 1 : 0 }]);
    };

    const updateExtension = (ID) => {
        if (ID === isSold[0].ID) {
            setSpinner(true);
        }
        else {
            return;
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/extensions/updateExtension`,
            data: {
                ID: ID,
                IsSold: isSold[0].isSold
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                checkForTodayData();
            }
        }).catch(() => {

        }).finally(() => {
            setSpinner(false);
        });
    }

    const deleteExtension = (ID) => {
        setDeleteSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/extensions/removeExtension`,
            data: {
                ID: ID,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                checkForTodayData();
            }
        }).catch(() => {

        }).finally(() => {
            setDeleteSpinner(false);
        });
    }

    React.useEffect(() => {
        checkForTodayData();
    }, []);

    return (
        <div className='component__extensionsModal'>
            <div className='component__extensionsModal__wrapper'>
                <div className='component__extensionsModal__wrapper__header'>
                    <p>Produženja i pozivi</p>
                    <img onClick={props.onClose} src='images/closemenu.svg' alt='' />
                </div>
                <div className='component__extensionsModal__wrapper__body'>
                    <div className='component__extensionsModal__wrapper__body__box'>
                        <p>Produženja danas</p>
                        {!dataSpinner && <div className='component__extensionsModal__wrapper__body__box__list'>
                            {data?.data?.map(item => {
                                if (item.Type === 'extension') {
                                    return <div className='component__extensionsModal__wrapper__body__box__list__lead'>
                                        <div onClick={(e) => animateBox(e, <LeadInfoModal offerID={item.OfferID} getAll={() => { }} user={props.userData} leadID={item.LeadID} agents={props.agents} />)} className='component__extensionsModal__wrapper__body__box__list__lead__info'>
                                            <p>Ime: {item.LeadName}</p>
                                            <p>Proizvod: {item.LeadProduct}</p>
                                            <p>Država: {item.LeadCountry}</p>
                                        </div>
                                        <div className='component__extensionsModal__wrapper__body__box__list__lead__actions'>
                                            <input onChange={(e) => handleCheckboxChange(e, item.ID)} type='checkbox' defaultChecked={item.IsSold ? 1 : 0} />
                                            <div onClick={() => updateExtension(item.ID)} className='component__extensionsModal__wrapper__body__box__list__lead__actions__btn'>
                                                {!spinner && <p>Sačuvaj</p>}
                                                {spinner && <Spinner align='center' style={{ marginTop: '0px', width: '14px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                                            </div>
                                        </div>
                                    </div>
                                }
                            })}
                        </div>}
                        {dataSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                    </div>
                    <div className='component__extensionsModal__wrapper__body__box'>
                        <p>Pozivi danas</p>
                        {!dataSpinner && <div className='component__extensionsModal__wrapper__body__box__list'>
                            {data?.data?.map(item => {
                                if (item.Type === 'callAgain') {
                                    return <div className='component__extensionsModal__wrapper__body__box__list__lead'>
                                        <div onClick={(e) => animateBox(e, <LeadInfoModal offerID={item.OfferID} getAll={() => { }} user={props.userData} leadID={item.LeadID} agents={props.agents} />)} className='component__extensionsModal__wrapper__body__box__list__lead__info'>
                                            <p>Ime: {item.LeadName}</p>
                                            <p>Proizvod: {item.LeadProduct}</p>
                                            <p>Država: {item.LeadCountry}</p>
                                        </div>
                                        <div className='component__extensionsModal__wrapper__body__box__list__lead__actions'>
                                            {!deleteSpinner && <img onClick={() => deleteExtension(item.ID)} src='images/trash.svg' alt='' />}
                                            {deleteSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                                        </div>
                                    </div>
                                }
                            })}
                        </div>}
                        {dataSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#ffffff' />}
                    </div>
                </div>
            </div>
        </div>
    )
}
