import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import Spinner from '../../components/Spinner';
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment';

export default function Statistics() {
  const [dropdown, setDropdown] = React.useState(false);
  const [selectedOffer, setSelectedOffer] = React.useState({ name: 'Svi offeri', value: 0 });
  const [error, setError] = React.useState(null);
  const [offersSinner, setOffersSpinner] = React.useState(true);
  const [offers, setOffers] = React.useState([]);
  const [pDropdown, setPdropdown] = React.useState(false);
  const [selectedPartner, setSelectedPartner] = React.useState({ name: 'Svi partneri', value: 0 });
  const [partnersSinner, setPartnersSpinner] = React.useState(true);
  const [partners, setPartners] = React.useState([]);
  const [stats, setStats] = React.useState([]);
  const [mainSpinner, setMainSpinner] = React.useState(true);
  const [trash, setTrash] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState(null);
  const [extensions, setExtensions] = React.useState(false);

  const getOffers = () => {
    setOffersSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/offers/getAllOffers`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setOffers(res.data);
      } else {
        return setError('Došlo je do greške 32');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setOffersSpinner(false);
    });
  }

  const getPartners = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/publishers/getAllPublishers`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setPartners(res.data);
      } else {
        return setError('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setPartnersSpinner(false);
    });
  }

  const getStats = () => {
    setMainSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getStats`,
      data: {
        OfferID: selectedOffer.value > 0 ? selectedOffer.value : null,
        PartnerID: selectedPartner.value > 0 ? selectedPartner.value : null,
        TrashStatus: trash ? null : 12,
        PublisherID: searchValue && String(searchValue).includes('P:') ? String(searchValue).replace('P:', '') : null,
        WebmasterID: searchValue && String(searchValue).includes('W:') ? String(searchValue).replace('W:', '') : null
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setStats(res.data);
      } else {
        return setError('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setMainSpinner(false);
    });
  }

  React.useEffect(() => {
    getOffers();
    getPartners();
  }, []);

  React.useEffect(() => {
    getStats();
  }, [selectedOffer, selectedPartner, trash]);

  return <div className='route__statistics'>
    <div className='route__statistics__header'>
      {!offersSinner && <div className='route__statistics__header__dropdown'>
        <div onClick={() => setDropdown(d => !d)} className={`route__statistics__header__dropdown__head ${dropdown ? 'route__statistics__header__dropdown__head--active' : ''}`}>
          <p>{selectedOffer.name}</p>
        </div>
        <div className={`route__statistics__header__dropdown__body ${dropdown ? 'route__statistics__header__dropdown__body--active' : ''}`}>
          {
            offers?.data?.map(item => {
              return <p onClick={() => { setSelectedOffer({ name: item.Name + ' ' + item.Type + ' ' + item.Country, value: item.OfferID }); setDropdown(false) }}>{item.Name} {item.Type} {item.Country}</p>
            })
          }
          <p onClick={() => { setSelectedOffer({ name: 'Svi offeri', value: 0 }); setDropdown(false) }}>Svi</p>
        </div>
      </div>}
      {offersSinner && <Spinner align='center' style={{ marginTop: '0px', width: '24px' }} color='#ffffff' />}
      {!partnersSinner && <div className='route__statistics__header__dropdown'>
        <div onClick={() => setPdropdown(d => !d)} className={`route__statistics__header__dropdown__head ${pDropdown ? 'route__statistics__header__dropdown__head--active' : ''}`}>
          <p>{selectedPartner.name}</p>
        </div>
        <div className={`route__statistics__header__dropdown__body ${pDropdown ? 'route__statistics__header__dropdown__body--active' : ''}`}>
          {
            partners?.data?.map(item => {
              return <p onClick={() => { setSelectedPartner({ name: item.Name, value: item.ID }); setPdropdown(false) }}>{item.Name}</p>
            })
          }
          <p onClick={() => { setSelectedPartner({ name: 'Svi partneri', value: 0 }); setPdropdown(false) }}>Svi</p>
        </div>
      </div>}
      {partnersSinner && <Spinner align='center' style={{ marginTop: '0px', width: '24px' }} color='#ffffff' />}
      <div onClick={() => setTrash(t => !t)} className='route__statistics__header__button'>
        <p>{trash ? 'Izbaci smeće' : 'Vrati smeće'}</p>
      </div>
      <div onClick={() => setExtensions(ex => !ex)} className='route__statistics__header__button'>
        <p>{extensions ? 'Ugasi produzenja' : 'Ukljuci produzenja'}</p>
      </div>
      {selectedPartner.value > 0 && <div className='route__statistics__header__search'>
        <input onChange={(e) => setSearchValue(e.currentTarget.value)} type='text' placeholder='W: hs783hs ili P: 5970' />
        <img onClick={() => getStats()} src='images/header__searchW.svg' alt='' />
      </div>}
    </div>

    {mainSpinner && <Spinner align='center' style={{ marginTop: '0px', width: '30px' }} color='#ffffff' />}
    {!mainSpinner && <div className='route__statistics__content'>
      <div className='route__statistics__content__column'>
        <h1>Danas</h1>
        <div className='route__statistics__content__column__grid'>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Ukupno leadova</p>
              <strong>{stats.data?.today[0]?.totalLeads}</strong>
            </div>
            <img src='images/leads.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Odobreno leadova</p>
              <strong>{stats.data?.today[0]?.approvedLeads == null ? 0 : stats.data?.today[0]?.approvedLeads}</strong>
            </div>
            <img src='images/conversion.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Approve rate</p>
              <strong>{stats.data?.today[0]?.approveRate == null ? 0 : stats.data?.today[0]?.approveRate}%</strong>
            </div>
            <img src='images/apprate.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosječna prodaja</p>
              <strong>{stats.data?.today[0]?.avgSaleValue == null ? 0 : stats.data?.today[0]?.avgSaleValue} EUR</strong>
            </div>
            <img src='images/avgsell.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosjek kutija</p>
              <strong>{stats.data?.today[0]?.avgQuantityPerLead == null ? 0 : stats.data?.today[0]?.avgQuantityPerLead}</strong>
            </div>
            <img src='images/avgk.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>% više od 1k</p>
              <strong>{stats.data?.today[0]?.multiPackagePercentage == null ? 0 : stats.data?.today[0]?.multiPackagePercentage}%</strong>
            </div>
            <img src='images/v1k.png' alt='' />
          </div>

        </div>
      </div>

      <div className='route__statistics__content__column'>
        <h1>Jučer</h1>
        <div className='route__statistics__content__column__grid'>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Ukupno leadova</p>
              <strong>{stats.data?.yesterday[0]?.totalLeads == null ? 0 : stats.data?.yesterday[0]?.totalLeads}</strong>
            </div>
            <img src='images/leads.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Odobreno leadova</p>
              <strong>{stats.data?.yesterday[0]?.approvedLeads == null ? 0 : stats.data?.yesterday[0]?.approvedLeads}</strong>
            </div>
            <img src='images/conversion.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Approve rate</p>
              <strong>{stats.data?.yesterday[0]?.approveRate == null ? 0 : stats.data?.yesterday[0]?.approveRate}%</strong>
            </div>
            <img src='images/apprate.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosječna prodaja</p>
              <strong>{stats.data?.yesterday[0]?.avgSaleValue == null ? 0 : stats.data?.yesterday[0]?.avgSaleValue} EUR</strong>
            </div>
            <img src='images/avgsell.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosjek kutija</p>
              <strong>{stats.data?.yesterday[0]?.avgQuantityPerLead == null ? 0 : stats.data?.yesterday[0]?.avgQuantityPerLead}</strong>
            </div>
            <img src='images/avgk.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>% više od 1k</p>
              <strong>{stats.data?.yesterday[0]?.multiPackagePercentage == null ? 0 : stats.data?.yesterday[0]?.multiPackagePercentage}%</strong>
            </div>
            <img src='images/v1k.png' alt='' />
          </div>

        </div>
      </div>

      <div className='route__statistics__content__column'>
        <h1>7 dana</h1>
        <div className='route__statistics__content__column__grid'>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Ukupno leadova</p>
              <strong>{stats.data?.last7Days[0]?.totalLeads}</strong>
            </div>
            <img src='images/leads.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Odobreno leadova</p>
              <strong>{stats.data?.last7Days[0]?.approvedLeads == null ? 0 : stats.data?.last7Days[0]?.approvedLeads}</strong>
            </div>
            <img src='images/conversion.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Approve rate</p>
              <strong>{stats.data?.last7Days[0]?.approveRate == null ? 0 : stats.data?.last7Days[0]?.approveRate}%</strong>
            </div>
            <img src='images/apprate.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosječna prodaja</p>
              <strong>{stats.data?.last7Days[0]?.avgSaleValue == null ? 0 : stats.data?.last7Days[0]?.avgSaleValue} EUR</strong>
            </div>
            <img src='images/avgsell.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosjek kutija</p>
              <strong>{stats.data?.last7Days[0]?.avgQuantityPerLead == null ? 0 : stats.data?.last7Days[0]?.avgQuantityPerLead}</strong>
            </div>
            <img src='images/avgk.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>% više od 1k</p>
              <strong>{stats.data?.last7Days[0]?.multiPackagePercentage == null ? 0 : stats.data?.last7Days[0]?.multiPackagePercentage}%</strong>
            </div>
            <img src='images/v1k.png' alt='' />
          </div>

        </div>
      </div>

      <div className='route__statistics__content__column'>
        <h1>Mjesec</h1>
        <div className='route__statistics__content__column__grid'>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Ukupno leadova</p>
              <strong>{stats.data?.currentMonth[0]?.totalLeads}</strong>
            </div>
            <img src='images/leads.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Odobreno leadova</p>
              <strong>{stats.data?.currentMonth[0]?.approvedLeads == null ? 0 : stats.data?.currentMonth[0]?.approvedLeads}</strong>
            </div>
            <img src='images/conversion.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Approve rate</p>
              <strong>{stats.data?.currentMonth[0]?.approveRate == null ? 0 : stats.data?.currentMonth[0]?.approveRate}%</strong>
            </div>
            <img src='images/apprate.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosječna prodaja</p>
              <strong>{stats.data?.currentMonth[0]?.avgSaleValue == null ? 0 : stats.data?.currentMonth[0]?.avgSaleValue} EUR</strong>
            </div>
            <img src='images/avgsell.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosjek kutija</p>
              <strong>{stats.data?.currentMonth[0]?.avgQuantityPerLead == null ? 0 : stats.data?.currentMonth[0]?.avgQuantityPerLead}</strong>
            </div>
            <img src='images/avgk.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>% više od 1k</p>
              <strong>{stats.data?.currentMonth[0]?.multiPackagePercentage == null ? 0 : stats.data?.currentMonth[0]?.multiPackagePercentage}%</strong>
            </div>
            <img src='images/v1k.png' alt='' />
          </div>

        </div>
      </div>

      <div className='route__statistics__content__column'>
        <h1>Ukupno <span style={{fontSize: '10px'}}>(od 01.09.2024.)</span></h1>
        <div className='route__statistics__content__column__grid'>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Ukupno leadova</p>
              <strong>{stats.data?.allTime[0]?.totalLeads}</strong>
            </div>
            <img src='images/leads.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Odobreno leadova</p>
              <strong>{stats.data?.allTime[0]?.approvedLeads == null ? 0 : stats.data?.allTime[0]?.approvedLeads}</strong>
            </div>
            <img src='images/conversion.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Approve rate</p>
              <strong>{stats.data?.allTime[0]?.approveRate == null ? 0 : stats.data?.allTime[0]?.approveRate}%</strong>
            </div>
            <img src='images/apprate.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosječna prodaja</p>
              <strong>{stats.data?.allTime[0]?.avgSaleValue == null ? 0 : stats.data?.allTime[0]?.avgSaleValue} EUR</strong>
            </div>
            <img src='images/avgsell.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>Prosjek kutija</p>
              <strong>{stats.data?.allTime[0]?.avgQuantityPerLead == null ? 0 : stats.data?.allTime[0]?.avgQuantityPerLead}</strong>
            </div>
            <img src='images/avgk.png' alt='' />
          </div>
          <div className='route__statistics__content__column__grid__kpi'>
            <div className='route__statistics__content__column__grid__kpi__left'>
              <p>% više od 1k</p>
              <strong>{stats.data?.allTime[0]?.multiPackagePercentage == null ? 0 : stats.data?.allTime[0]?.multiPackagePercentage}%</strong>
            </div>
            <img src='images/v1k.png' alt='' />
          </div>
        </div>
      </div>
    </div>}
    {extensions && <ExtensionsStats offerID={selectedOffer.value} />}
  </div>
}

const ExtensionsStats = (props) => {
  const [spinner, setSpinner] = React.useState(true);
  const [stats, setStats] = React.useState([]);
  const [error, setError] = React.useState(null);

  const getStats = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/extensions/getStats`,
      data: {
        OfferID: props.offerID ? props.offerID : null
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setStats(res.data);
      } else {
        return setError('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    getStats();
  }, []);

  if (!spinner) {
    return <div className='route__statistics__extensions'>
      <h1>Statistika za produženja</h1>
      <div className='route__statistics__extensions__content'>
        <div className='route__statistics__extensions__content__column'>
          <h1>Danas</h1>
          <div className='route__statistics__extensions__content__column__grid'>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Ukupno produzenja</p>
                <strong>{stats.data?.today[0]?.totalExtensions == null ? 0 : stats.data?.today[0]?.totalExtensions}</strong>
              </div>
              <img src='images/upselling.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Prodano produzenja</p>
                <strong>{stats.data?.today[0]?.soldExtensions == null ? 0 : stats.data?.today[0]?.soldExtensions}</strong>
              </div>
              <img src='images/conversion.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Approve rate</p>
                <strong>{stats.data?.today[0]?.approveRate == null ? 0 : stats.data?.today[0]?.approveRate}%</strong>
              </div>
              <img src='images/apprate.png' alt='' />
            </div>

          </div>
        </div>

        <div className='route__statistics__extensions__content__column'>
          <h1>Jučer</h1>
          <div className='route__statistics__extensions__content__column__grid'>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Ukupno produzenja</p>
                <strong>{stats.data?.yesterday[0]?.totalExtensions == null ? 0 : stats.data?.yesterday[0]?.totalExtensions}</strong>
              </div>
              <img src='images/upselling.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Prodano produzenja</p>
                <strong>{stats.data?.yesterday[0]?.soldExtensions == null ? 0 : stats.data?.yesterday[0]?.soldExtensions}</strong>
              </div>
              <img src='images/conversion.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Approve rate</p>
                <strong>{stats.data?.yesterday[0]?.approveRate == null ? 0 : stats.data?.yesterday[0]?.approveRate}%</strong>
              </div>
              <img src='images/apprate.png' alt='' />
            </div>

          </div>
        </div>

        <div className='route__statistics__extensions__content__column'>
          <h1>7 dana</h1>
          <div className='route__statistics__extensions__content__column__grid'>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Ukupno produzenja</p>
                <strong>{stats.data?.last7Days[0]?.totalExtensions == null ? 0 : stats.data?.last7Days[0]?.totalExtensions}</strong>
              </div>
              <img src='images/upselling.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Prodano produzenja</p>
                <strong>{stats.data?.last7Days[0]?.soldExtensions == null ? 0 : stats.data?.last7Days[0]?.soldExtensions}</strong>
              </div>
              <img src='images/conversion.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Approve rate</p>
                <strong>{stats.data?.last7Days[0]?.approveRate == null ? 0 : stats.data?.last7Days[0]?.approveRate}%</strong>
              </div>
              <img src='images/apprate.png' alt='' />
            </div>

          </div>
        </div>

        <div className='route__statistics__content__column'>
          <h1>Mjesec</h1>
          <div className='route__statistics__extensions__content__column__grid'>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Ukupno produzenja</p>
                <strong>{stats.data?.currentMonth[0]?.totalExtensions == null ? 0 : stats.data?.currentMonth[0]?.totalExtensions}</strong>
              </div>
              <img src='images/upselling.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Prodano produzenja</p>
                <strong>{stats.data?.currentMonth[0]?.soldExtensions == null ? 0 : stats.data?.currentMonth[0]?.soldExtensions}</strong>
              </div>
              <img src='images/conversion.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Approve rate</p>
                <strong>{stats.data?.currentMonth[0]?.approveRate == null ? 0 : stats.data?.currentMonth[0]?.approveRate}%</strong>
              </div>
              <img src='images/apprate.png' alt='' />
            </div>

          </div>
        </div>

        <div className='route__statistics__extensions__content__column'>
          <h1>Ukupno</h1>
          <div className='route__statistics__extensions__content__column__grid'>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Ukupno produzenja</p>
                <strong>{stats.data?.allTime[0]?.totalExtensions == null ? 0 : stats.data?.allTime[0]?.totalExtensions}</strong>
              </div>
              <img src='images/upselling.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Prodano produzenja</p>
                <strong>{stats.data?.allTime[0]?.soldExtensions == null ? 0 : stats.data?.allTime[0]?.soldExtensions}</strong>
              </div>
              <img src='images/conversion.png' alt='' />
            </div>
            <div className='route__statistics__extensions__content__column__grid__kpi'>
              <div className='route__statistics__extensions__content__column__grid__kpi__left'>
                <p>Approve rate</p>
                <strong>{stats.data?.allTime[0]?.approveRate == null ? 0 : stats.data?.allTime[0]?.approveRate}%</strong>
              </div>
              <img src='images/apprate.png' alt='' />
            </div>

          </div>
        </div>
      </div>
    </div>
  }
  else if (spinner) {
    return <Spinner align='center' style={{ marginTop: '0px', width: '30px' }} color='#ffffff' />
  }
}